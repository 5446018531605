import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Box, Message, displayErrorMessage } from "@clearabee/ui-library";

/**
 * Import components.
 */
import { NoContactDetailsSwitch, PONumberField } from "../components";
import {
  FormInput,
  FormSelect,
  FormTextarea,
  Checkbox,
  ImageUpload,
} from "../../../core";

/**
 * Import hooks.
 */
import {
  useAuthContext,
  useBasketContext,
  useCatalogueContext,
  useMultiFormContext,
} from "../../../../hooks";

/**
 * Import types.
 */
import {
  getItemFromCatalogueBySku,
  getDeliveryOptions,
  getDeliveryOptionsForDropdown,
  getDeliveryOptionChargeFromOption,
} from "../../../../helpers/catalogue";
import { ICreateJobFormState } from "../createJob";

import { getCollectionDetailsSchema } from "../validation";
import { ISelectOption } from "../../../core/select/simple";
import roles from "../../../../constants/roles";

/**
 * Interface.
 */
interface IImage {
  file: File;
  dataUrl: string;
}

export const CollectionDetails = (): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { doesUserHaveRole } = useAuthContext();
  const isAdmin = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const { catalogueItems } = useCatalogueContext();
  const { hasItem, deleteItem, addItem, items } = useBasketContext();
  const permitCharge = getItemFromCatalogueBySku(
    catalogueItems || [],
    "SKIPPERMIT",
  );

  /**
   * Selected item
   */
  const selectedCatalogueItem = getItemFromCatalogueBySku(
    catalogueItems || [],
    items.length && items[0].sku ? items[0].sku : "",
  );

  /**
   * Delivery options
   */
  const deliveryOptions = getDeliveryOptions(selectedCatalogueItem);
  const { timeslotPreferenceOptions } =
    getDeliveryOptionsForDropdown(deliveryOptions);

  // start from 8am to 6pm
  const customTimeslotOptions = Array.from({ length: 11 }, (_, i) => {
    if (i + 8 === 18) return { label: "18:00:00", value: 18 };

    return Array.from({ length: 4 }, (_, j) => ({
      label: `${(i + 8).toString().padStart(2, "0")}:${(j * 15)
        .toString()
        .padStart(2, "0")}:00`,
      value: i + 8 + j * 0.25,
    }));
  }).flatMap((option) => option);

  const {
    formState: {
      accessInformation,
      collectionAddress,
      customer: {
        phoneNumber: customerPhone,
        email: customerEmail,
        firstName,
        lastName,
      },
      meta: { poNumber, wasteType, zendeskTicketId, what3words },
      noContactDetails,
      timeslotPreference,
      isCustomTimeslot,
      customTimeslot,
      timeslot,
      wasteDescription,
      landType,
      company,
      reportsMetaValidation,
      sicCode,
      images: formStateImages,
      useAdhoc,
      companyOrderNumberRequired,
      orderNumberLabel,
      orderNumberValidation,
      orderNumberValidationMessage,
      contactDetailsRequired,
      phoneAndEmailRequired,
      reportsMeta,
      bigchangeProps: {
        cust_RiskAddressIsCollectionAddress,
        cust_RiskPostcode,
        "cust_Is the customer vulnerable": custIsCustomerVulnerable,
        "cust_VC Notes": custVcNotes,
      },
    },
    pushState,
    nextStep,
  } = useMultiFormContext<ICreateJobFormState>();

  const [t] = useTranslation("jobs");

  const isVT = false;
  const isSkip = hasItem("SKIPS", true);

  const companyCode = !!company?.value ? company.value : "";

  const handleSubmit = (state: any) => {
    const { email, firstName, lastName, phoneNumber } = state.customer;

    if (state && state.meta && state.meta.poNumber) {
      state.meta = {
        ...state.meta,
        poNumber: state.meta.poNumber.replace(/\s/g, ""),
      };
    }

    if (state && state.isCustomTimeslot === true) {
      const startTimeLabel = customTimeslotOptions.find(
        ({ value }) => value === state.customTimeslot.startTime,
      )?.label;
      const endTimeLabel = customTimeslotOptions.find(
        ({ value }) => value === state.customTimeslot.endTime,
      )?.label;

      state.timeslot = `${startTimeLabel}-${endTimeLabel}`;
    }

    pushState({
      ...state,
      formStateImages,
      customer: {
        email: email.replace(/\s/g, ""),
        phoneNumber: phoneNumber.replace(/\s/g, ""),
        firstName,
        lastName,
      },
    });
    nextStep();
  };

  /**
   * This sets the form values to be whatever is
   * within the formState at the present time.
   * If the form has not yet been filled out, these will
   * be blank. However if the form has been filled out, and then
   * returned to from a later step, it will prefill
   * the form with the values that were entered in the first time
   * around. This provides a good UX should the user want
   * to edit details they have already entered once.
   */
  const initialValues = {
    accessInformation: accessInformation,
    customer: {
      email: customerEmail ?? "",
      firstName,
      lastName,
      phoneNumber: customerPhone ?? "",
    },
    images: formStateImages || [],
    meta: {
      poNumber,
      wasteType,
      zendeskTicketId,
      what3words,
    },
    noContactDetails: noContactDetails || false,
    timeslot,
    timeslotPreference,
    isCustomTimeslot,
    customTimeslot,
    wasteDescription: wasteDescription,
    landType: isSkip ? landType : "",
    wasteTypes: wasteType
      ? wasteType.split(",").map((eachWasteType) => {
          return { value: eachWasteType, label: eachWasteType };
        })
      : null,
    sicCode,
    reportsMeta,
    bigchangeProps: {
      cust_RiskAddressIsCollectionAddress,
      cust_RiskPostcode: cust_RiskAddressIsCollectionAddress
        ? cust_RiskPostcode
        : "",
      "cust_Is the customer vulnerable": custIsCustomerVulnerable,
      "cust_VC Notes": custIsCustomerVulnerable ? custVcNotes : "",
    },
  };

  /**
   * Clear any delivery charge on mount of this step.
   * As user has to select new timeslot each time.
   */
  useEffect(() => {
    deleteItem("DELIVERYCHARGE");
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getCollectionDetailsSchema({
        /**
         * The validationSchema will vary for this form, dependent
         * on which product the user has selected and which collection
         * options are available for those products. This is passing the booleans
         * of which prop is true
         */
        isSkip,
        hasTimeslots: deliveryOptions !== null && deliveryOptions.length > 0,
        orderNumberRequired: companyOrderNumberRequired,
        requirePhoneAndEmail: phoneAndEmailRequired,
        orderNumberValidation: orderNumberValidation ?? "",
        orderNumberValidationMessage: orderNumberValidationMessage ?? "",
        reportsMeta: reportsMetaValidation,
      })}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnBlur
    >
      {({
        handleSubmit,
        setFieldValue,
        validateField,
        isValid,
        values,
        errors,
        submitCount,
      }) => {
        useEffect(() => {
          validateField("timeslotPreference");
        }, [values.timeslotPreference]);

        useEffect(() => {
          if (!values.bigchangeProps.cust_RiskAddressIsCollectionAddress) {
            setFieldValue("bigchangeProps.cust_RiskPostcode", "");
          }
        }, [values.bigchangeProps.cust_RiskAddressIsCollectionAddress]);

        return (
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-center bg-white w-full shadow-filter min-h-64 px-8 py-12 rounded-xl">
              <div className="md:flex md:flex-row py-2 md:py-6 -mx-2 lg:-mx-4 xl:-mx-6">
                <div className="w-full md:w-2/5 lg:w-1/4 px-2 lg:px-4 xl:px-6">
                  <h4 className="text-primary font-semibold">
                    {t("create.steps.collectionDetails.titles.collection")}
                  </h4>
                  {!useAdhoc && (
                    <div className="mt-6">
                      <PONumberField
                        label={orderNumberLabel}
                        postcode={collectionAddress.postcode}
                        companyCode={companyCode}
                        required={companyOrderNumberRequired}
                      />
                    </div>
                  )}
                  {isAdmin && !useAdhoc && (
                    <div className="mt-6">
                      <FormInput
                        name="meta.zendeskTicketId"
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.zendeskTicketId",
                        )}
                        label={{
                          text: t(
                            "create.steps.collectionDetails.form.labels.zendeskTicketId",
                          ),
                        }}
                        floatingLabel={false}
                      />
                    </div>
                  )}
                  <div className="mt-6">
                    <FormInput
                      name="sicCode"
                      placeholder={t(
                        "create.steps.collectionDetails.form.placeholders.sicCode",
                      )}
                      label={{
                        text: t(
                          "create.steps.collectionDetails.form.labels.sicCode",
                        ),
                      }}
                      floatingLabel={false}
                    />
                  </div>
                  {!!deliveryOptions?.length && (
                    <div className="mt-6">
                      <FormSelect
                        name="timeslotPreference"
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.timeslotPreference",
                        )}
                        alwaysSetValue={false}
                        floatingLabel={false}
                        clearable
                        disabled={values.isCustomTimeslot}
                        options={timeslotPreferenceOptions}
                        label={{
                          text: `${t(
                            "create.steps.collectionDetails.form.labels.timeslotPreference",
                          )}*`,
                        }}
                        onChange={(option) => {
                          setFieldValue("customTimeslot.startTime", "");
                          setFieldValue("customTimeslot.endTime", "");
                          setFieldValue("customTimeslot.charge", 0);

                          if (option !== null) {
                            setFieldValue("timeslot", "");
                            setFieldValue("timeslotPreference", option.value);

                            const charge = getDeliveryOptionChargeFromOption(
                              option,
                              deliveryOptions,
                            );
                            setFieldValue("timeslotCharge", charge);
                          } else {
                            setFieldValue("timeslotPreference", "");
                          }
                        }}
                      />
                    </div>
                  )}
                  {values.timeslotPreference &&
                    !!deliveryOptions?.find(
                      ({ title }) => title === values.timeslotPreference,
                    )?.range?.length && (
                      <div className="mt-6">
                        <FormSelect
                          name="timeslot"
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.timeslot",
                          )}
                          alwaysSetValue={false}
                          floatingLabel={false}
                          options={
                            getDeliveryOptionsForDropdown(
                              deliveryOptions.filter(
                                ({ title }) =>
                                  title === values.timeslotPreference,
                              ),
                            ).timeslotOptions
                          }
                          label={{
                            text: t(
                              "create.steps.collectionDetails.form.labels.timeslot",
                            ),
                          }}
                          onChange={(option: ISelectOption) => {
                            /**
                             * On change, update the field in the Formik context as normal.
                             */
                            if (option !== null) {
                              setFieldValue("timeslot", option.value);
                            } else {
                              setFieldValue("timeslot", "");
                            }
                          }}
                        />
                      </div>
                    )}
                  {/* CUSTOM TIMESLOT FOR CLEARABEE ADMIN AND STAFF ONLY */}
                  {isAdmin && (
                    <div className="mt-6">
                      <label>
                        <p className="font-medium text-sm">
                          {t(
                            "create.steps.collectionDetails.form.labels.isCustomTimeslot",
                          )}
                        </p>
                      </label>
                      <Checkbox
                        name="isCustomTimeslot"
                        onClick={() => {
                          // reset custom timeslots fields, timeslotPreference, timeslot, timeslotCharge everytime toggle is on/off
                          setFieldValue("customTimeslot.startTime", "");
                          setFieldValue("customTimeslot.endTime", "");
                          setFieldValue("customTimeslot.charge", 0);
                          setFieldValue("timeslot", "");
                          setFieldValue("timeslotPreference", "");
                          setFieldValue("timeslotCharge", 0);

                          setFieldValue(
                            "isCustomTimeslot",
                            !values.isCustomTimeslot,
                          );
                        }}
                      />
                    </div>
                  )}
                  {!!values.isCustomTimeslot && (
                    <>
                      <div className="mt-6">
                        <FormSelect
                          name="customTimeslot.startTime"
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.customStartTime",
                          )}
                          alwaysSetValue={false}
                          floatingLabel={false}
                          options={customTimeslotOptions.filter(
                            ({ label }) => label !== "18:00:00",
                          )}
                          label={{
                            text: `${t(
                              "create.steps.collectionDetails.form.labels.customStartTime",
                            )}*`,
                          }}
                          clearable
                          onChange={(option: ISelectOption) => {
                            /**
                             * On change, update the field in the Formik context as normal.
                             */

                            if (option === null) {
                              setFieldValue("customTimeslot.startTime", "");
                              setFieldValue("customTimeslot.endTime", "");
                              return;
                            }

                            setFieldValue(
                              "customTimeslot.startTime",
                              option.value,
                            );
                          }}
                        />
                      </div>
                      <div className="mt-6">
                        <FormSelect
                          name="customTimeslot.endTime"
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.customEndTime",
                          )}
                          alwaysSetValue={false}
                          floatingLabel={false}
                          options={
                            !!values?.customTimeslot?.startTime
                              ? customTimeslotOptions.filter(
                                  ({ value }) =>
                                    Number(value) >
                                    Number(values.customTimeslot.startTime),
                                )
                              : []
                          }
                          label={{
                            text: `${t(
                              "create.steps.collectionDetails.form.labels.customEndTime",
                            )}*`,
                          }}
                          clearable
                          disabled={!values?.customTimeslot?.startTime}
                          onChange={(option: ISelectOption) => {
                            /**
                             * On change, update the field in the Formik context as normal.
                             */
                            if (option === null) {
                              return setFieldValue(
                                "customTimeslot.endTime",
                                "",
                              );
                            }

                            setFieldValue(
                              "customTimeslot.endTime",
                              option.value,
                            );
                          }}
                        />
                      </div>
                      <div className="mt-6">
                        <FormInput
                          name="customTimeslot.charge"
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.customCharge",
                          )}
                          label={{
                            text: t(
                              "create.steps.collectionDetails.form.labels.customCharge",
                            ),
                          }}
                          prefix={"£"}
                          type="number"
                          floatingLabel={false}
                        />
                      </div>
                    </>
                  )}
                  {/* if companyCode starts with LV- */}
                  {!useAdhoc && !!companyCode && companyCode.includes("LV-") && (
                    <div className="mt-6">
                      <label>
                        <p className="font-medium text-sm">
                          {t(
                            "create.steps.collectionDetails.form.labels.isRiskAddressDifferentToCollectionAddress",
                          )}
                        </p>
                      </label>
                      <Checkbox name="bigchangeProps.cust_RiskAddressIsCollectionAddress" />
                      {values.bigchangeProps
                        .cust_RiskAddressIsCollectionAddress && (
                        <div className="mt-6">
                          <FormInput
                            name="bigchangeProps.cust_RiskPostcode"
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.riskPostcode",
                            )}
                            label={{
                              text: `${t(
                                "create.steps.collectionDetails.form.labels.riskPostcode",
                              )}*`,
                            }}
                            floatingLabel={false}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {/* if companyCode starts with DIR- */}
                  {!useAdhoc && !!companyCode && companyCode.includes("DIR-") && (
                    <div className="mt-6">
                      <label>
                        <p className="font-medium text-sm">
                          {t(
                            "create.steps.collectionDetails.form.labels.isCustomerVulnerable",
                          )}
                        </p>
                      </label>
                      <Checkbox name="bigchangeProps.cust_Is the customer vulnerable" />
                      {values.bigchangeProps[
                        "cust_Is the customer vulnerable"
                      ] && (
                        <div className="mt-6">
                          <FormTextarea
                            name="bigchangeProps.cust_VC Notes"
                            placeholder={t(
                              "create.steps.collectionDetails.form.placeholders.vcNotes",
                            )}
                            label={{
                              text: `${t(
                                "create.steps.collectionDetails.form.labels.vcNotes",
                              )}*`,
                            }}
                            floatingLabel={false}
                            maxLength={499}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {isSkip && (
                    <div className="mt-6">
                      <h4 className="text-primary font-semibold mb-6">
                        Skip Hire
                      </h4>
                      <FormSelect
                        name="landType"
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.landType",
                        )}
                        alwaysSetValue={false}
                        floatingLabel={false}
                        options={[
                          {
                            value: "Private Land",
                            label: "Private Land",
                          },
                          {
                            value: "Public Land",
                            label: `Public Land${
                              permitCharge !== null
                                ? ` (+£${permitCharge.price || "0.00"})`
                                : ""
                            }`,
                          },
                        ]}
                        label={{
                          text: t(
                            "create.steps.collectionDetails.form.labels.landType",
                          ),
                        }}
                        onChange={(option: any) => {
                          /**
                           * On change, update the field in the Formik context as normal.
                           */
                          if (option !== null) {
                            setFieldValue("landType", option.value);
                            if (option.value === "Public Land") {
                              // add permit charge
                              if (permitCharge !== null) {
                                addItem({
                                  sku: permitCharge.sku,
                                  quantity: 1,
                                  price: permitCharge.price || 0,
                                  title: permitCharge.title,
                                });
                              }
                            } else {
                              deleteItem("SKIPPERMIT");
                            }
                          } else {
                            setFieldValue("landType", "");
                            deleteItem("SKIPPERMIT");
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className="mt-6 md:mt-10">
                    <h4 className="text-primary font-semibold">
                      {t("create.steps.collectionDetails.titles.fullAddress")}
                    </h4>
                    <div className="mt-2">
                      {collectionAddress.hasOwnProperty("line1") && (
                        <p>{collectionAddress.line1}</p>
                      )}
                      {collectionAddress.hasOwnProperty("line2") && (
                        <p>{collectionAddress.line2}</p>
                      )}
                      {collectionAddress.hasOwnProperty("city") && (
                        <p>{collectionAddress.city}</p>
                      )}
                      {collectionAddress.hasOwnProperty("county") && (
                        <p>{collectionAddress.county}</p>
                      )}
                      {collectionAddress.hasOwnProperty("postcode") && (
                        <p>{collectionAddress.postcode}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full mt-6 md:mt-0 md:w-1/3 lg:w-1/4 px-2 lg:px-4 xl:px-6">
                  <h4 className="text-primary font-semibold">
                    {t("create.steps.collectionDetails.titles.customer")}
                  </h4>
                  {!contactDetailsRequired && (
                    <div>
                      {!useAdhoc && (
                        <div className="mt-6">
                          <NoContactDetailsSwitch
                            name="noContactDetails"
                            label={t(
                              "create.steps.collectionDetails.form.labels.noContactDetails",
                            )}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {values.noContactDetails === false && (
                    <>
                      <div className="mt-6">
                        <FormInput
                          name="customer.firstName"
                          placeholder="First Name"
                          label={{
                            text: t(
                              "create.steps.collectionDetails.form.labels.firstName",
                            ),
                          }}
                          floatingLabel={false}
                          type="text"
                        />
                      </div>
                      <div className="mt-6">
                        <FormInput
                          name="customer.lastName"
                          placeholder="Last Name"
                          label={{
                            text: t(
                              "create.steps.collectionDetails.form.labels.lastName",
                            ),
                          }}
                          floatingLabel={false}
                        />
                      </div>
                      <div className="mt-6">
                        <FormInput
                          name="customer.phoneNumber"
                          placeholder={t(
                            "table.headings.internationalContactPhone",
                          )}
                          label={{
                            text: t(
                              "create.steps.collectionDetails.form.labels.customerPhone",
                            ),
                          }}
                          floatingLabel={false}
                        />
                      </div>
                      <div className="mt-6">
                        <FormInput
                          name="customer.email"
                          placeholder={t(
                            "create.steps.collectionDetails.form.placeholders.customerEmail",
                          )}
                          label={{
                            text: t(
                              "create.steps.collectionDetails.form.labels.customerEmail",
                            ),
                          }}
                          floatingLabel={false}
                        />
                      </div>
                      {!isVT && (
                        <div>
                          <div className="mt-6 text-xs bg-orange text-white px-2 py-1 rounded-md font-semibold">
                            {t(
                              `create.steps.collectionDetails.form.messages.${
                                phoneAndEmailRequired
                                  ? "emailAndPhone"
                                  : "emailOrPhone"
                              }`,
                            )}
                            <br />
                            <br />
                            {t(
                              "create.steps.collectionDetails.form.messages.internationalContactPhone",
                            )}
                            <br />
                            {t("table.headings.internationalContactPhone")}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {isVT && (
                    <div className="mt-3">
                      <Checkbox
                        name="customerNoEmail"
                        label={t(
                          "create.steps.collectionDetails.form.labels.customerNoEmail",
                        )}
                      />
                    </div>
                  )}
                </div>

                <div className="w-auto mt-6 md:mt-0 lg:w-1/2 px-2 lg:px-4 xl:px-6">
                  <h4 className="text-primary font-semibold">
                    {t("create.steps.collectionDetails.titles.waste")}
                  </h4>
                  {!isVT && (
                    <div className="mt-6">
                      <FormSelect
                        name="wasteTypes"
                        placeholder={t(
                          "create.steps.collectionDetails.form.placeholders.wasteType",
                        )}
                        alwaysSetValue={false}
                        floatingLabel={false}
                        searchable={true}
                        isMulti={true}
                        options={[
                          {
                            value: "Hazardous Waste (Consignment*)",
                            label: "Hazardous Waste (Consignment*)",
                          },
                          {
                            value: "Paper & Cardboard (20 01 01)",
                            label: "Paper & Cardboard (20 01 01)",
                          },
                          {
                            value: "Glass (20 01 02)",
                            label: "Glass (20 01 02)",
                          },
                          {
                            value: "Non Hazardous WEEE (16 02 14)",
                            label: "Non Hazardous WEEE (16 02 14)",
                          },
                          {
                            value: "Bulky Waste (20 03 07)",
                            label: "Bulky Waste (20 03 07)",
                          },
                          {
                            value: "Wood (17 02 01)",
                            label: "Wood (17 02 01)",
                          },
                          {
                            value: "Food Waste (20 01 08)",
                            label: "Food Waste (20 01 08)",
                          },
                          {
                            value: "Construction (17 09 04)",
                            label: "Construction (17 09 04)",
                          },
                          {
                            value: "Offensive Waste (20 01 99)",
                            label: "Offensive Waste (20 01 99)",
                          },
                          {
                            value: "Organic Food Waste (20 01 08)",
                            label: "Organic Food Waste (20 01 08)",
                          },
                          {
                            value: "Gypsum (17 08 01)",
                            label: "Gypsum (17 08 01)",
                          },
                          {
                            value: "Waste Plastics (02 01 04)",
                            label: "Waste Plastics (02 01 04)",
                          },
                          {
                            value: "Garden Waste (20 02 01)",
                            label: "Garden Waste (20 02 01)",
                          },
                          {
                            value: "Mixed Municipal (20 03 01)",
                            label: "Mixed Municipal (20 03 01)",
                          },
                          {
                            value: "Mixed Metals (17 04 07)",
                            label: "Mixed Metals (17 04 07)",
                          },
                          {
                            value: "Other (Not listed)",
                            label: "Other (Not listed)",
                          },
                        ]}
                        label={{
                          text: t(
                            "create.steps.collectionDetails.form.labels.wasteType",
                          ),
                        }}
                        onChange={(option: any) => {
                          /**
                           * On change, update the field in the Formik context as normal.
                           */
                          if (option !== null) {
                            setFieldValue("wasteTypes", option);

                            // Convert option array to string
                            const val = option
                              .map((item: any) => item.value)
                              .join(",");

                            // Assign string value to meta.wasteType field
                            setFieldValue("meta.wasteType", val);
                          } else {
                            setFieldValue("wasteTypes", "");
                            setFieldValue("meta.wasteType", "");
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className="mt-6">
                    <FormTextarea
                      name="wasteDescription"
                      placeholder={t(
                        "create.steps.collectionDetails.form.placeholders.wasteDescription",
                      )}
                      label={{
                        text: t(
                          "create.steps.collectionDetails.form.labels.wasteDescription",
                        ),
                      }}
                      floatingLabel={false}
                      maxLength={499}
                    />
                  </div>
                  <div className="mt-6">
                    <FormTextarea
                      name="accessInformation"
                      placeholder={t(
                        "create.steps.collectionDetails.form.placeholders.accessInformation",
                      )}
                      label={{
                        text: t(
                          "create.steps.collectionDetails.form.labels.accessInformation",
                        ),
                      }}
                      floatingLabel={false}
                      maxLength={499}
                    />
                  </div>
                  <div className="mt-6">
                    <ImageUpload
                      defaultValues={formStateImages || []}
                      buttonClassName="ut-transition"
                      buttonText={t(
                        "create.steps.collectionDetails.form.buttons.uploadImages",
                      )}
                      onChange={(files: IImage[]) =>
                        setFieldValue("images", files)
                      }
                    />
                  </div>
                  {!!reportsMetaValidation?.length && (
                    <div className="mt-4">
                      <h4 className="text-primary font-semibold">
                        {t(
                          "create.steps.collectionDetails.titles.additionalFields",
                        )}
                      </h4>
                      <div className="grid grid-cols-2 gap-3">
                        {reportsMetaValidation.map((field, index: number) => (
                          <div key={index} className="mt-6">
                            <FormInput
                              name={`reportsMeta.${field.name}`}
                              placeholder={field.label}
                              label={{ text: field.label }}
                              floatingLabel={false}
                              type="text"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-10 flex flex-col items-center justify-center">
              <button
                data-testid="test-next"
                name="next"
                type="submit"
                className="btn-secondary hover:btn-secondary-hover transition ease-in duration-100"
                disabled={
                  !!deliveryOptions?.find(
                    ({ title }) => title === values.timeslotPreference,
                  )?.range?.length && !values.timeslot
                }
              >
                Next
              </button>
              {/* Display Form Fields Error */}
              {!!submitCount &&
                !isValid &&
                !!Object.keys(errors).length &&
                displayErrorMessage(
                  translate("create.errorFieldsRequired"),
                  ({ children }) => (
                    <Box className="flex flex-col items-center mt-4">
                      <Message
                        background={true}
                        type="error"
                        styles={{
                          textAlign: "center",
                        }}
                      >
                        {children}
                      </Message>
                    </Box>
                  ),
                )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
