import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { FieldArray } from "formik";
import { UpdateCompanyFormValues } from "../../api/types";
import {
  updateCompany,
  readCompany,
  getCatalogues,
  getCatalogueByCompanyCode,
  assignCatalogueToCompanies,
  removeCatalogueFromCompanies,
} from "../../api";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import {
  constantDays,
  defaultDates,
  defaultDays,
  defaultReportsMeta,
  updateCompany as validation,
} from "./validation";
import { toasts } from "../../helpers/toasts";
import {
  Button,
  Field,
  Heading,
  Input,
  Panel,
  theme,
  Icon,
  Form,
  useModal,
  hexToRgba,
  UnstyledButton,
  Box,
  Modal,
  Message,
  Text,
} from "@clearabee/ui-library";
import { LeftChevron } from "images";
import { ViewCompanyDocuments } from "components/common/components";

export const UpdateCompany = (): React.ReactElement => {
  const { doesUserHaveRole } = useAuthContext();
  const isClearabeeManager = doesUserHaveRole(roles.CLEARABEE_MANAGER);
  const isClearabeeAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const { id } = useParams() as any;
  const history = useHistory();
  const [translate] = useTranslation("companies");
  const [disableContactDetailsToggle, setDisableContactDetailsToggle] =
    useState(false);
  const [SuccessModal, setSuccessModal] = useModal();
  const [showReportsMeta, setShowReportsMeta] = useState(false);

  const {
    data: catalogueData,
    isError: catalogueError,
    isFetching: catalogueFetching,
  } = useQuery(["getCatalogues"], () => getCatalogues({ limit: 1000 }));

  const {
    isFetching,
    isLoading,
    data: companyData,
    error,
  } = useQuery(["readCompany", id], () => readCompany(id), {
    // prevent caching to show newly updated values
    cacheTime: 0,
    staleTime: 0,
    onError: () => {
      toasts.error({
        message: translate("errors.fetchingCompany"),
      });
    },
  });

  const {
    data: companyCatalogue,
    isFetching: companyCatalogueFetching,
    isError: companyCatalogueError,
  } = useQuery(
    ["getCompanyCatalogue", companyData?.companyCode],
    () => getCatalogueByCompanyCode(companyData?.companyCode || ""),
    {
      enabled: !!companyData?.companyCode,
      retry: 0,
    },
  );

  const { mutate, isLoading: isUpdateCompanyLoading } = useMutation(
    async ({
      catalogue,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      companyCode,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      companyPrefix,
      // companyCode and companyPrefix are removed from the company object, but are
      // not themselves used in anything, so they throw the un-used var lint error
      ...updatedCompany
    }: UpdateCompanyFormValues) => {
      await updateCompany(id, updatedCompany);

      if (!companyData?.companyCode) {
        return;
      }
      if (catalogue === "" && !!companyCatalogue) {
        await removeCatalogueFromCompanies(companyCatalogue?.id, [
          companyData.companyCode,
        ]);
      } else if (catalogue && catalogue !== companyCatalogue?.id) {
        await assignCatalogueToCompanies(catalogue, [companyData.companyCode]);
      }
    },
    {
      onError: () => {
        toasts.error({
          message: translate("errors.updateError"),
        });
      },
      onSuccess: () => setSuccessModal(true),
    },
  );

  const {
    data: companyDocuments,
    isLoading: documentsAreLoading,
    isRefetching: documentsAreRefetching,
    isError: documentsAreError,
    refetch: refetchCompanyDocuments,
  } = useQuery(
    ["getCompanyDocuments", JSON.stringify(companyData?.documents)],
    async () => {
      if (!!companyData?.documents) {
        return await Promise.all(
          companyData?.documents.map(async ({ url, description, type }) => {
            // as we wanted to get the name of the document, we need to decode the url
            // cases: clearabee%2F1707824133195-clearabee-signature.png -> clearabee/1707824133195-clearabee-signature.png
            // 1707824133195-clearabee-signature.png is what we want to get
            const splitUrl = decodeURIComponent(url).split("/");

            const imageUrl = (
              await axios.get(
                `${process.env.REACT_APP_MS_API_BASE_URL}/companies/${
                  companyData.id
                }/documents/${splitUrl[splitUrl.length - 1]}`,
              )
            ).data;

            return {
              documentName: description,
              imageUrl,
              type,
            };
          }),
        );
      }
    },
    {
      enabled: !!companyData?.documents,
      retry: false,
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const initial: UpdateCompanyFormValues = {
    // required
    name: companyData?.name || "",
    companyCode: companyData?.companyCode || "",
    companyPrefix: companyData?.companyPrefix || "",
    addressLine1: companyData?.addressLine1 || "",
    addressPostcode: companyData?.addressPostcode || "",
    contactName: companyData?.contactName || "",
    contactEmail: companyData?.contactEmail || "",
    contactPhoneNumber: companyData?.contactPhoneNumber || "",
    settings: {
      canInvoice: companyData?.settings?.canInvoice || false,
      hidePrices: companyData?.settings?.hidePrices || false,
      hideInvoices: companyData?.settings?.hideInvoices || false,
      requireOrderNumber: companyData?.settings?.requireOrderNumber || false,
      orderNumberLabel: companyData?.settings?.orderNumberLabel || "",
      requireContactDetails:
        companyData?.settings?.requireContactDetails || false,
      requirePhoneAndEmail:
        companyData?.settings?.requirePhoneAndEmail || false,
      orderNumberValidation: companyData?.settings?.orderNumberValidation || "",
      orderNumberValidationMessage:
        companyData?.settings?.orderNumberValidationMessage || "",
      hidePhotos: companyData?.settings?.hidePhotos || false,
      hideTransferNotes: companyData?.settings?.hideTransferNotes || false,
      identityProvider: companyData?.settings?.identityProvider || "",
      ssoOnly: companyData?.settings?.ssoOnly || false,
      bucketKey: companyData?.settings?.bucketKey || "",
      services: companyData?.settings?.services || [],
      monthlyVolume: companyData?.settings?.monthlyVolume || "",
    },
    active: companyData?.active || false,
    // optional
    invoiceAddress: {
      addressLine1: companyData?.invoiceAddress?.addressLine1 || "",
      addressLine2: companyData?.invoiceAddress?.addressLine2 || "",
      city: companyData?.invoiceAddress?.city || "",
      postcode: companyData?.invoiceAddress?.postcode || "",
    },
    type: companyData?.type || undefined,
    addressLine2: companyData?.addressLine2 || "",
    bookingLimitDates: companyData?.bookingLimitDates || [],
    bookingLimitDays: companyData?.bookingLimitDays || [],
    reportsMeta: companyData?.reportsMeta || [],
    addressCity: companyData?.addressCity || "",
    addressCounty: companyData?.addressCounty || "",
    sicCode: companyData?.sicCode || "",
    resourceId: companyData?.resourceId,
    priority: companyData?.priority || 0,
    bigChangeContactId: companyData?.bigChangeContactId ?? 0,
    catalogue:
      !companyCatalogueError && companyCatalogue
        ? companyCatalogue.id
        : undefined,
    notes: companyData?.notes || "",
  };

  const handleSubmit = (values: UpdateCompanyFormValues) => {
    const invoiceAddressHasValues =
      values.invoiceAddress &&
      Object.values(values.invoiceAddress).some((value) => !!value);

    mutate({
      ...values,
      priority: values.priority || 0,
      bookingLimitDays: values.bookingLimitDays?.map((item) => ({
        ...item,
        day: Number(item.day),
      })),
      invoiceAddress: invoiceAddressHasValues ? values.invoiceAddress : null,
    });
  };

  if (error) return <Redirect to="/companies" />;

  return (
    <>
      <Box className="max-w-screen-lg py-5 sm:px-4 mx-auto">
        {isLoading || isFetching || companyCatalogueFetching ? (
          <Icon.Loading color="brand" styles={{ margin: "auto" }} />
        ) : (
          <>
            <UnstyledButton
              onClick={() => history.goBack()}
              className="text-sm inline-flex items-center mb-4 justify-start"
            >
              <LeftChevron />
              <span className="ml-2">
                {translate("common.backToCompanies")}
              </span>
            </UnstyledButton>
            <Form
              initialValues={initial}
              validationSchema={validation}
              onSubmit={handleSubmit}
            >
              {({ errors, submitCount, values, setFieldValue }) => {
                useEffect(() => {
                  setDisableContactDetailsToggle(false);
                  if (values.settings.requirePhoneAndEmail) {
                    setDisableContactDetailsToggle(true);
                    setFieldValue("settings.requireContactDetails", true);
                  }
                }, [values.settings.requirePhoneAndEmail]);

                return (
                  <Panel>
                    <Box className="flex flex-col md:flex-row justify-between md:space-x-8">
                      <Box className="w-full md:w-1/2">
                        <Heading level={2} color="brand" fontSize="large">
                          {translate("form.headings.company")}
                        </Heading>
                        <Field
                          name="name"
                          label={translate("form.labels.companyName")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          styles={{ marginBottom: "0px" }}
                          name="bigChangeContactId"
                          label={translate("form.labels.bigChangeContactId")}
                        >
                          {({ field }) => <Input.Text {...field} disabled />}
                        </Field>
                        <Box className="xl:grid xl:gap-4 xl:grid-cols-2">
                          <Field
                            name="companyCode"
                            label={translate("form.labels.companyCode")}
                          >
                            {({ field }) => <Input.Text {...field} disabled />}
                          </Field>
                          <Field
                            name="companyPrefix"
                            label={translate("form.labels.companyPrefix")}
                          >
                            {({ field }) => <Input.Text {...field} disabled />}
                          </Field>
                        </Box>
                        {isClearabeeAdmin && (
                          <Field
                            name="type"
                            label="Type"
                            styles={{ margin: "0px" }}
                          >
                            {({ field }) => (
                              <Input.Select
                                {...field}
                                options={[
                                  { label: "Commercial", value: "commercial" },
                                  {
                                    label: "Subcontractor",
                                    value: "subcontractor",
                                  },
                                ]}
                              />
                            )}
                          </Field>
                        )}
                        <Box className="border-t border-gray-400 mt-8 xl:mt-4 pb-2" />
                        <Field
                          name="addressLine1"
                          label={translate("form.labels.addressLine1")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="addressLine2"
                          label={translate("form.labels.addressLine2")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="addressCity"
                          label={translate("form.labels.townCity")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="addressCounty"
                          label={translate("form.labels.county")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Box className="lg:grid lg:gap-x-4 lg:-my-4 lg:grid-cols-2">
                          <Field
                            name="addressPostcode"
                            label={translate("form.labels.postcode")}
                          >
                            {({ field }) => (
                              <Input.Text
                                {...field}
                                disabled={isClearabeeManager}
                              />
                            )}
                          </Field>
                          <Field
                            name="sicCode"
                            label={translate("form.labels.sicCode")}
                          >
                            {({ field }) => (
                              <Input.Text
                                {...field}
                                disabled={isClearabeeManager}
                              />
                            )}
                          </Field>
                        </Box>
                        {isClearabeeAdmin && (
                          <>
                            <Field name="resourceId" label="Resource Id">
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  type="number"
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>
                            <Field name="priority" label="Priority">
                              {({ field }) => (
                                <Input.Text {...field} type="number" />
                              )}
                            </Field>
                          </>
                        )}
                        <Field
                          name="notes"
                          label={translate("form.labels.notes")}
                        >
                          {({ field }) => (
                            <Input.Textarea
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <Heading level={2} color="brand" fontSize="large">
                          {translate("form.headings.invoiceAddress")}
                        </Heading>
                        <Field
                          name="invoiceAddress.addressLine1"
                          label={translate("form.labels.addressLine1")}
                        >
                          {({ field }) => <Input.Text {...field} />}
                        </Field>
                        <Field
                          name="invoiceAddress.addressLine2"
                          label={translate("form.labels.addressLine2")}
                        >
                          {({ field }) => <Input.Text {...field} />}
                        </Field>
                        <Box className="flex gap-3 w-full">
                          <Box className="w-full">
                            <Field
                              name="invoiceAddress.city"
                              label={translate("form.labels.townCity")}
                            >
                              {({ field }) => <Input.Text {...field} />}
                            </Field>
                          </Box>
                          <Box className="w-full">
                            <Field
                              name="invoiceAddress.postcode"
                              label={translate("form.labels.postcode")}
                            >
                              {({ field }) => <Input.Text {...field} />}
                            </Field>
                          </Box>
                        </Box>
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <FieldArray
                          name="bookingLimitDays"
                          render={({ remove, push }) => (
                            <>
                              <Box className="flex mt-3">
                                <Heading level={4} color="brand">
                                  {translate("form.headings.bookingLimitDays")}
                                </Heading>
                              </Box>
                              {values.bookingLimitDays?.map((item, index) => (
                                <Box
                                  key={`bookingLimitDays-${index}-${item.day}`}
                                  className="flex items-center"
                                >
                                  <Box className="w-full h-24 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.day",
                                      )}
                                      name={`bookingLimitDays[${index}].day`}
                                    >
                                      {({ field }) => {
                                        return (
                                          <Input.Select
                                            {...field}
                                            placeholder={translate(
                                              "filters.form.placeholder.selectDay",
                                            )}
                                            options={constantDays.filter(
                                              ({ value }) =>
                                                value === item.day.toString() ||
                                                !values.bookingLimitDays?.some(
                                                  (item) =>
                                                    item.day.toString() ===
                                                    value,
                                                ),
                                            )}
                                            defaultValue={String(item.day)}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Box>
                                  <Box className="w-2/5 h-4 -mt-20 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.limit",
                                      )}
                                      name={`bookingLimitDays[${index}].limit`}
                                    >
                                      {({ field }) => (
                                        <Input.Text
                                          {...field}
                                          type="number"
                                          placeholder={translate(
                                            "filters.form.label.limit",
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Box>
                                  <UnstyledButton
                                    className="mt-5"
                                    onClick={() => remove(index)}
                                  >
                                    <Icon.Trash
                                      size="medium"
                                      color="negative"
                                    />
                                  </UnstyledButton>
                                </Box>
                              ))}
                              <Box className="flex justify-start mt-5 pr-8">
                                <Button
                                  disabled={
                                    values.bookingLimitDays &&
                                    values.bookingLimitDays?.length > 6
                                  }
                                  onClick={() => {
                                    push(defaultDays);
                                  }}
                                  size="small"
                                  type="button"
                                >
                                  {translate(
                                    "steps.productOptions.table.headings.add",
                                  )}
                                </Button>
                              </Box>
                            </>
                          )}
                        />
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <FieldArray
                          name="bookingLimitDates"
                          render={({ push, remove }) => (
                            <>
                              <Box className="flex mt-3">
                                <Heading level={4} color="brand">
                                  {translate("form.headings.bookingLimitDates")}
                                </Heading>
                              </Box>
                              {values.bookingLimitDates?.map((item, index) => (
                                <Box
                                  key={`${index}-${item.date}`}
                                  className="flex items-center"
                                >
                                  <Box className="w-full h-24 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.date",
                                      )}
                                      name={`bookingLimitDates[${index}].date`}
                                    >
                                      {({ field }) => (
                                        <Input.Date
                                          {...field}
                                          dateFormat="YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                          collapsable
                                          disabledDays={(day) =>
                                            values.bookingLimitDates?.some(
                                              (item) =>
                                                dayjs(item.date).format(
                                                  "YYYY-MM-DD",
                                                ) ===
                                                dayjs(day).format("YYYY-MM-DD"),
                                            )
                                          }
                                          initialValue={dayjs(item.date).format(
                                            "YYYY-MM-DD",
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </Box>
                                  <Box className="w-2/5 h-4 -mt-20 mr-3">
                                    <Field
                                      label={translate(
                                        "filters.form.label.limit",
                                      )}
                                      name={`bookingLimitDates[${index}].limit`}
                                    >
                                      {({ field }) => (
                                        <Input.Text
                                          type="number"
                                          {...field}
                                          defaultValue={item.limit}
                                        />
                                      )}
                                    </Field>
                                  </Box>
                                  <UnstyledButton
                                    className="mt-5"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <Icon.Trash
                                      size="medium"
                                      color="negative"
                                    />
                                  </UnstyledButton>
                                </Box>
                              ))}
                              <Box className="flex justify-start mt-5 pr-8">
                                <Button
                                  onClick={() => {
                                    push(defaultDates);
                                  }}
                                  size="small"
                                  type="button"
                                >
                                  {translate(
                                    "steps.productOptions.table.headings.add",
                                  )}
                                </Button>
                              </Box>
                            </>
                          )}
                        />
                      </Box>
                      {/* Divider */}
                      <Box className="border-t border-gray-400 mt-4 pb-6" />
                      <Box className="w-full md:w-1/2">
                        <Heading level={2} color="brand" fontSize="large">
                          {translate("form.headings.contact")}
                        </Heading>
                        <Field
                          name="contactName"
                          label={translate("form.labels.name")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="contactEmail"
                          label={translate("form.labels.emailAddress")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Field
                          name="contactPhoneNumber"
                          label={translate("form.labels.phone")}
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={isClearabeeManager}
                            />
                          )}
                        </Field>
                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <Heading level={4} color="brand" fontSize="large">
                          Catalogue
                        </Heading>
                        <Box className={catalogueError ? "mt-3" : "mt-4"}>
                          {!catalogueError ? (
                            <>
                              <Field
                                name="catalogue"
                                label={translate(
                                  "form.labels.companyCatalogue",
                                )}
                              >
                                {({ field }) => (
                                  <Input.Select
                                    {...field}
                                    isClearable
                                    isSearchable
                                    isLoading={catalogueFetching}
                                    placeholder={translate(
                                      "form.placeholders.selectCompanyCatalogue",
                                    )}
                                    options={
                                      catalogueData?.items.map((catalogue) => ({
                                        label: catalogue.title,
                                        value: catalogue.id,
                                      })) ?? []
                                    }
                                    defaultValue={field.value ?? ""}
                                  />
                                )}
                              </Field>
                              {values?.catalogue && (
                                <Link
                                  to={`/catalogues/update/${values.catalogue}`}
                                >
                                  <Button
                                    as="button"
                                    styles={{
                                      display: "inline-block",
                                    }}
                                    size="medium"
                                  >
                                    {translate(
                                      "form.buttons.labels.viewCatalogue",
                                    )}
                                  </Button>
                                </Link>
                              )}
                            </>
                          ) : (
                            <>
                              {companyCatalogue && (
                                <Box className="mb-3">
                                  <label className="font-semibold text-sm">
                                    {translate("form.labels.companyCatalogue")}:
                                  </label>
                                  <p>{companyCatalogue.title}</p>
                                </Box>
                              )}
                              <Box className="bg-red-500 text-white w-full p-3 text-center rounded-lg">
                                <span className="block">
                                  {translate("errors.fetchingCatalogues")}
                                </span>
                              </Box>
                            </>
                          )}
                        </Box>

                        {/* Divider */}
                        <Box className="border-t border-gray-400 mt-8 pb-8" />

                        <Panel
                          shadow={false}
                          styles={{
                            backgroundColor: hexToRgba(
                              theme.colors.greyscale.lightest,
                              0.4,
                            ),
                          }}
                        >
                          <Heading level={3} color="brand" fontSize="large">
                            {translate("form.headings.settings")}
                          </Heading>
                          <Box className="flex flex-col">
                            <Field name="active" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.companyActive")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field name="settings.canInvoice" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  label={translate("form.labels.canInvoice")}
                                  color="positive"
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field name="settings.hideInvoices" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.hideInvoices")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field name="settings.hidePrices" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.hidePrices")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.requireOrderNumber"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate(
                                    "form.labels.requireOrderNumber",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.orderNumberLabel"
                              label={translate(
                                "form.labels.redefineOrderNumberLabel",
                              )}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.orderNumberValidation"
                              label={translate(
                                "form.labels.orderNumberValidation",
                              )}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  placeholder={translate(
                                    "form.placeholders.orderNumberValidation",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.orderNumberValidationMessage"
                              label={translate(
                                "form.labels.orderNumberValidationMessage",
                              )}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.requireContactDetails"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  disabled={
                                    disableContactDetailsToggle ||
                                    isClearabeeManager
                                  }
                                  color="positive"
                                  label={translate(
                                    "form.labels.requireContactDetails",
                                  )}
                                  styles={{
                                    color: disableContactDetailsToggle
                                      ? "lightGrey"
                                      : "black",
                                  }}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.requirePhoneAndEmail"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate(
                                    "form.labels.requirePhoneAndEmail",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.hideTransferNotes"
                              type="checkbox"
                            >
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate(
                                    "form.labels.hideTransferNotes",
                                  )}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field name="settings.hidePhotos" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.hidePhotos")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field
                              name="settings.identityProvider"
                              label={translate("form.labels.identityProvider")}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            <Field name="settings.ssoOnly" type="checkbox">
                              {({ field }) => (
                                <Input.Toggle
                                  {...field}
                                  color="positive"
                                  label={translate("form.labels.ssoOnly")}
                                  disabled={isClearabeeManager}
                                />
                              )}
                            </Field>

                            {companyData?.settings?.bucketKey && (
                              <Box className="mb-5">
                                <Text className="font-bold">
                                  {translate("form.labels.bucketKey")}
                                </Text>

                                <Text>{companyData?.settings?.bucketKey}</Text>
                              </Box>
                            )}

                            {companyData?.settings?.services && (
                              <Box className="mb-5">
                                <Text className="font-bold">
                                  {translate("form.labels.services")}
                                </Text>

                                {companyData?.settings?.services?.map(
                                  (service) => (
                                    <Text key={service}>{service}</Text>
                                  ),
                                )}
                              </Box>
                            )}

                            {companyData?.settings?.monthlyVolume && (
                              <Box>
                                <Text className="font-bold">
                                  {translate("form.labels.monthlyVolume")}
                                </Text>

                                <Text>
                                  {companyData?.settings?.monthlyVolume}
                                </Text>
                              </Box>
                            )}
                          </Box>
                        </Panel>

                        <Box className="border-t border-gray-400 mt-8 pb-6" />
                        <Box className="flex justify-between items-center">
                          <Heading level={4} color="brand">
                            {translate("form.headings.reportsMeta")} (
                            {values?.reportsMeta?.length ?? 0})
                          </Heading>
                          <Button
                            size="small"
                            type="button"
                            onClick={() => {
                              setShowReportsMeta(true);
                            }}
                          >
                            {translate("modal.buttons.labels.edit")}
                          </Button>
                        </Box>
                        {!!errors.reportsMeta && !showReportsMeta && (
                          <Message className="mt-3" type="error" background>
                            {translate("errors.reportsMetaError")}
                          </Message>
                        )}
                        <Modal
                          modalVisible={showReportsMeta}
                          width={1000}
                          closeButtonStyles={{
                            top: 15,
                            right: 15,
                          }}
                          styles={{
                            [`@media (min-width: ${theme.screens.medium})`]: {
                              padding: theme.spacing.xlarge,
                            },
                          }}
                          onClose={() => setShowReportsMeta(false)}
                        >
                          <FieldArray
                            name="reportsMeta"
                            render={({ push, remove }) => (
                              <>
                                <Box className="flex justify-between items-center mb-2">
                                  <Heading level={3} color="brand">
                                    {translate("form.headings.reportsMeta")}
                                  </Heading>
                                  <Button
                                    onClick={() => {
                                      push(defaultReportsMeta);
                                    }}
                                    size="small"
                                    type="button"
                                  >
                                    {translate(
                                      "steps.productOptions.table.headings.add",
                                    )}
                                  </Button>
                                </Box>
                                {values.reportsMeta?.map(
                                  (reportsMetaItem, index) => (
                                    <Box
                                      key={`reportsMeta-${index}`}
                                      className="flex text-left gap-3"
                                    >
                                      <Box className="h-24">
                                        <Field
                                          label={translate("form.labels.name")}
                                          name={`reportsMeta[${index}].name`}
                                        >
                                          {({ field }) => (
                                            <Input.Text {...field} />
                                          )}
                                        </Field>
                                      </Box>
                                      <Box className="h-24">
                                        <Field
                                          label={translate("form.labels.label")}
                                          name={`reportsMeta[${index}].label`}
                                        >
                                          {({ field }) => (
                                            <Input.Text {...field} />
                                          )}
                                        </Field>
                                      </Box>
                                      <Box className="h-24">
                                        <Field
                                          label={translate("form.labels.regex")}
                                          name={`reportsMeta[${index}].regex`}
                                        >
                                          {({ field }) => (
                                            <Input.Text {...field} />
                                          )}
                                        </Field>
                                      </Box>
                                      <Box className="h-24 flex-grow">
                                        <Field
                                          label={translate(
                                            "form.labels.validationMessage",
                                          )}
                                          name={`reportsMeta[${index}].validationMessage`}
                                        >
                                          {({ field }) => (
                                            <Input.Text {...field} />
                                          )}
                                        </Field>
                                      </Box>
                                      <Box className="h-24">
                                        <Field
                                          label={translate(
                                            "form.labels.required",
                                          )}
                                          name={`reportsMeta[${index}].required`}
                                        >
                                          {({ field }) => (
                                            <Input.Toggle
                                              {...field}
                                              checked={
                                                (reportsMetaItem as any)
                                                  .required
                                              }
                                              defaultChecked={
                                                (reportsMetaItem as any)
                                                  .required
                                              }
                                            />
                                          )}
                                        </Field>
                                      </Box>
                                      <UnstyledButton
                                        className="mt-5"
                                        onClick={() => {
                                          remove(index);
                                        }}
                                      >
                                        <Icon.Trash
                                          size="medium"
                                          color="negative"
                                        />
                                      </UnstyledButton>
                                    </Box>
                                  ),
                                )}
                              </>
                            )}
                          />
                        </Modal>
                      </Box>
                    </Box>

                    <Box className="flex justify-end items-center gap-3 pt-5 mt-5 mb-3">
                      <Button
                        disabled={isUpdateCompanyLoading}
                        type="submit"
                        color="accent"
                        size="medium"
                      >
                        {translate("form.buttons.labels.save")}
                      </Button>
                    </Box>
                    {!!submitCount && !!Object.keys(errors).length && (
                      <Box className="flex justify-end">
                        <Message type="error" background>
                          {translate("errors.fieldsMissing")}
                        </Message>
                      </Box>
                    )}

                    {/* DIVIDER */}
                    <Box className="border-t border-gray-400 mt-8 pb-8" />
                    <ViewCompanyDocuments
                      companyCode={values.companyCode}
                      documents={companyDocuments}
                      isLoading={documentsAreLoading}
                      isRefetching={documentsAreRefetching}
                      isError={documentsAreError}
                      reloadOnClick={() => refetchCompanyDocuments()}
                    />
                  </Panel>
                );
              }}
            </Form>
          </>
        )}
      </Box>

      <SuccessModal
        width={400}
        heading={translate("modal.headings.updateSuccess")}
        onClose={() => setSuccessModal(false)}
        actions={
          <>
            <Button
              size="medium"
              color="accent"
              onClick={() => history.push("/companies")}
            >
              {translate("modal.buttons.labels.backToCompanies")}
            </Button>
          </>
        }
      />
    </>
  );
};
