import React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import cx from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

/**
 * Import components.
 */
import { JobStatus } from "../../components";
import { FormButton } from "../../../core";
import {
  Button,
  useModal,
  theme,
  Panel,
  UnstyledButton,
  Icon,
  Text,
  Modal,
  Box,
} from "@clearabee/ui-library";
import { useAuthContext } from "hooks";
import roles from "constants/roles";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";
import { DownloadIcon } from "../../../../images";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/common/components";

interface JobDetailsProps {
  id: number;
  status: string;
  jobRef: string;
  collection: string;
  plannedStartTime: string;
  isFetching: boolean;
  canBookJobAgain: boolean;
  companyCode?: string;
  jobData: ApiResponseData<typeof instance.jobs.getJobByRef>;
  isCancelling: boolean;
  cancelJob: () => void;
  editJob: () => void;
  setBookJobAgainOpen: (value: boolean) => void;
  downloadWTN: () => void;
  isDownloadingWTN: boolean;
  refreshWTN: () => void;
  isRefreshingWTN: boolean;
}

dayjs.extend(utc);

export const JobDetails = ({
  status,
  collection,
  plannedStartTime,
  jobRef,
  isFetching,
  cancelJob,
  editJob,
  isCancelling,
  canBookJobAgain,
  companyCode,
  jobData,
  id,
  setBookJobAgainOpen,
  downloadWTN,
  isDownloadingWTN,
  refreshWTN,
  isRefreshingWTN,
}: JobDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");
  const { doesUserHaveRole, getCurrentUserCurrentCompanySettings } =
    useAuthContext();
  const [CancelModal, showCancelModal] = useModal();
  const { hideInvoices, hideTransferNotes } =
    getCurrentUserCurrentCompanySettings() || {};
  const etaStart = plannedStartTime
    ? dayjs.utc(plannedStartTime).subtract(30, "minutes")
    : "";
  const etaEnd = plannedStartTime ? dayjs(etaStart).add(3, "hours") : "";

  const isViewOnly = doesUserHaveRole(roles.VIEW_ONLY);
  const isWTNReady =
    jobData.collectionInformation?.wasteTypes &&
    jobData.collectionInformation.cubicYardsCollected &&
    jobData.collectionInformation.estimatedWeight;
  const editDisabled = [
    "Completed",
    "Started",
    "Failed",
    "Cancelled",
    "On the way",
  ].some((index) => status === index);

  const {
    isLoading: isDownloadingInvoice,
    isError: isInvoiceFailed,
    reset: resetInvoice,
    mutate: downloadInvoice,
  } = useMutation(
    async () => {
      return (await instance.jobs.getJobInvoicePdf(String(id))).data;
    },
    {
      onSuccess: async (data) => {
        const blob = await fetch(data.location).then((r) => r.blob());
        const a = document.createElement("a");
        a.href = (window as any).URL.createObjectURL(blob);
        a.download = data.fileKey;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    },
  );

  return (
    <>
      <CancelModal
        width={400}
        heading={translate("update.modal.heading")}
        onClose={() => showCancelModal(false)}
        actions={
          <Box className="mt-6">
            <Button
              onClick={cancelJob}
              color="accent"
              className="mr-5"
              size="medium"
              disabled={isCancelling}
            >
              {translate("update.modal.yes")}
            </Button>
            <Button
              onClick={() => showCancelModal(false)}
              size="medium"
              color="negative"
            >
              {translate("update.modal.no")}
            </Button>
          </Box>
        }
      />
      <Modal
        modalVisible={isInvoiceFailed}
        onClose={resetInvoice}
        width={400}
        styles={{
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: theme.spacing.xlarge,
          },
        }}
        closeButtonStyles={{ right: 15, top: 15 }}
      >
        <Box className="flex justify-center mb-4">
          <Icon.Attention color="warning" size="xlarge3" />
        </Box>
        <Text fontSize="large" className="font-semibold">
          {translate("update.modal.invoiceUnavailable")}
        </Text>
      </Modal>
      <Panel
        styles={{
          marginBottom: theme.spacing.large,
          padding: theme.spacing.medium,
        }}
        shadow={false}
      >
        <Box className="flex flex-wrap sm:flex-no-wrap mb-6 md:mb-10 relative">
          <h3 className="text-xl text-primary font-bold mx-auto mb-5 sm:mb-0 sm:mx-0 flex-shrink-0">
            {translate("headings.jobDetails")}
          </h3>
          <Box className="sm:ml-5 flex w-full">
            {!isViewOnly && (
              <Box>
                <FormButton
                  name="edit"
                  text="Edit Job"
                  type="button"
                  disabled={editDisabled}
                  variant="secondary"
                  className="h-8 mr-3 leading-3"
                  onClick={editJob}
                />
                <FormButton
                  name="cancel"
                  text="Cancel Job"
                  type="button"
                  variant="danger"
                  className="h-8 leading-3"
                  disabled={editDisabled}
                  onClick={() => showCancelModal(true)}
                />
              </Box>
            )}
            <Box className="ml-auto flex gap-x-3 items-center">
              {canBookJobAgain && (
                <Button
                  size="medium"
                  color="brand"
                  type="button"
                  styles={{ marginLeft: "auto" }}
                  onClick={() => setBookJobAgainOpen(true)}
                >
                  {translate("update.buttons.bookAgain")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {isFetching && (
          <Box
            className={cx(
              "bg-opacity-75 transition-all duration-500 rounded-lg flex items-center justify-center text-primary z-10",
              {
                "opacity-0 invisible": !isFetching,
              },
            )}
          >
            {translate("common:loading")}
          </Box>
        )}
        {!isFetching && (
          <>
            <Box className="flex flex-wrap -mx-2 justify-between text-gray-600 text-sm">
              <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                <h3 className="py-2 sm:py-3 font-bold text-black">
                  {translate("headings.status")}
                </h3>
                <JobStatus
                  row={{
                    original: { status },
                  }}
                />
              </Box>
              {!!jobData.meta?.jobResult && (
                <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.jobResult")}
                  </h3>
                  {jobData.meta?.jobResult}
                </Box>
              )}
              <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                <h3 className="py-2 sm:py-3 font-bold text-black">
                  {translate("headings.collectionDate")}
                </h3>
                {dayjs(collection).format("dddd, MMMM Do YYYY")}
              </Box>
              {!!etaStart && !!etaEnd && (
                <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.eta")}
                  </h3>
                  {etaStart.format("h:mm A")} - {etaEnd.format("h:mm A")}
                </Box>
              )}
              <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                <h3 className="py-2 sm:py-3 font-bold text-black">
                  {translate("headings.jobReference")}
                </h3>
                {jobRef}
              </Box>
              {companyCode && (
                <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.companyCode")}
                  </h3>
                  {companyCode}
                </Box>
              )}
              {!hideInvoices && (
                <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <h3 className="py-2 sm:py-3 font-bold text-black">
                    {translate("headings.invoices")}
                  </h3>
                  {!isDownloadingInvoice && status !== "Completed" && (
                    <span>{translate("common:none")}</span>
                  )}
                  {!isDownloadingInvoice && status === "Completed" && (
                    <UnstyledButton onClick={() => downloadInvoice()}>
                      <span className="flex text-sm flex-row leading-3  text-tertiary hover:text-primary-700 duration-300">
                        <span className="mr-2 font-semibold">
                          {translate("update.buttons.download")}
                        </span>
                        <DownloadIcon width={10} height={10} />
                      </span>
                    </UnstyledButton>
                  )}
                  {isDownloadingInvoice && (
                    <Box className="flex justify-center">
                      <Icon.Loading size="small" />
                    </Box>
                  )}
                </Box>
              )}
              {!hideTransferNotes && (
                <Box className="w-1/2 lg:w-auto px-2 mb-3 md:mb-0">
                  <Box className="flex items-center gap-x-2">
                    <h3 className="py-2 sm:py-3 font-bold text-black">
                      {translate("headings.wasteTransferNotes")}
                    </h3>
                    <UnstyledButton
                      styles={{
                        cursor: isRefreshingWTN ? "not-allowed" : "pointer",
                        opacity: isRefreshingWTN ? 0.5 : 1,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                      onClick={() => refreshWTN()}
                      disabled={isRefreshingWTN}
                    >
                      <Icon.Refresh size="small" />
                    </UnstyledButton>
                  </Box>
                  {isRefreshingWTN && (
                    <Icon.Loading
                      size="small"
                      color="brand"
                      className="mx-auto"
                    />
                  )}
                  {!isRefreshingWTN && isWTNReady && (
                    <UnstyledButton onClick={downloadWTN}>
                      {isDownloadingWTN ? (
                        <p className="text-sm  text-gray-600">Downloading...</p>
                      ) : (
                        <span className="flex text-sm flex-row leading-3  text-tertiary hover:text-primary-700 duration-300">
                          <span className="mr-2 font-semibold">Download</span>
                          <DownloadIcon width={10} height={10} />
                        </span>
                      )}
                    </UnstyledButton>
                  )}
                  {!isRefreshingWTN && !isWTNReady && (
                    <Tooltip>
                      <TooltipTrigger className="ml-1 flex items-center gap-1">
                        {translate("labels.pending")}
                        <Icon.Question color="brand" size="small" />
                      </TooltipTrigger>
                      <TooltipContent
                        style={{ zIndex: 10 }}
                        className="Tooltip"
                      >
                        <Panel
                          styles={{
                            padding: theme.spacing.small,
                            width: "350px",
                            textAlign: "left",
                            whiteSpace: "pre-line",
                            borderColor: theme.colors.greyscale.lightest,
                            borderWidth: "1px",
                          }}
                          shadow={false}
                        >
                          <Text fontSize="small">
                            {translate("messages.wtnTooltip")}
                          </Text>
                        </Panel>
                      </TooltipContent>
                    </Tooltip>
                  )}
                </Box>
              )}
            </Box>
          </>
        )}
      </Panel>
    </>
  );
};
