import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Form,
  Field,
  Input,
  Heading,
  Button,
  Icon,
  displayErrorMessage,
  Message,
  UnstyledButton,
  Box,
  Modal,
  Text,
  theme,
  Panel,
} from "@clearabee/ui-library";
import { useAuthContext } from "../../../hooks";
import roles from "../../../constants/roles";
import "./../styles/create.css";

/**
 * Import components.
 */
import {
  BookJobAgain,
  JobDetails,
  JobImages,
  WorksheetsAnswers,
  TipDetails,
  AllocateTip,
  ReportDetails,
} from "./components";
import { FormLoader } from "../../common/components";
import { Transition } from "../../core";

/**
 * Import API.
 */
import { editJob } from "api";

/**
 * Import helpers.
 */
import { getErrorMessage, toasts } from "../../../helpers";

/**
 * Import types.
 */
import { TEditJob } from "../../../api/types";
import { updateJobValidation } from "./validation";
import { instance } from "@clearabee/ui-sdk";
import { AdditionalInfo } from "./components/additionalInfo";

export const UpdateJob = (): React.ReactElement => {
  const { doesUserHaveRole, getCurrentUserCurrentCompanySettings } =
    useAuthContext();
  const { hidePhotos } = getCurrentUserCurrentCompanySettings() || {};
  const isAdmin = doesUserHaveRole(roles.CLEARABEE_ADMIN);
  const isClearabeeUser = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
    roles.CLEARABEE_OPERATIONS,
    roles.CLEARABEE_MANAGER,
  ]);
  const priorityLimit = isAdmin ? 100 : 50;
  const [t] = useTranslation("jobs");
  const { id: encodedId } = useParams<{ id: string }>();
  const history = useHistory();
  const jobId = decodeURIComponent(encodedId);
  const validationSchema = Yup.object().shape({
    ...updateJobValidation,
    priority: Yup.number()
      .moreThan(0, "Priority must be more than 0")
      .lessThan(
        priorityLimit + 1,
        "Priority must be less than or equal to " + priorityLimit,
      ),
  });

  const [editing, setEditing] = useState(false);
  const [bookJobAgainOpen, setBookJobAgainOpen] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showRefreshWTNModal, setShowRefreshWTNModal] = useState(false);

  const {
    data: jobData,
    isLoading: isLoadingJob,
    refetch: refetchJob,
  } = useQuery(
    ["readJob", encodedId],
    async () => (await instance.jobs.getJob(encodedId)).data,
    {
      enabled: !!encodedId,
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
    },
  );

  /**
   * get job reports
   */
  const { data: jobReports, isLoading: isLoadingJobReports } = useQuery(
    ["getJobReports", jobData?.ref, isClearabeeUser],
    async () => {
      if (!jobData?.ref || !isClearabeeUser) return;

      return (
        await instance.reports.getJobsByRef(encodeURIComponent(jobData?.ref))
      ).data;
    },
    {
      retry: false,
      enabled: !!jobData?.ref && isClearabeeUser,
    },
  );

  /**
   * Get company settings for the job's company.
   */
  const { data: jobCompanySettings } = useQuery(
    ["getJobCompanySettings", jobData?.companyCode],
    async () => {
      if (!jobData?.companyCode) return;

      const { data } = await axios.get(
        `/companies?companyCode:eq=${companyCode}&limit=1&offset=0`,
      );

      return data?.items?.[0]?.settings;
    },
    {
      retry: false,
      enabled: !!jobData?.companyCode,
    },
  );

  /**
   * Check if the job can be invoiced, so that the user can book this job again.
   */
  const canBookJobAgain = jobCompanySettings?.canInvoice ?? false;

  const { mutate: mutateTransferNotePdf, isLoading: isLoadingTransferNotePdf } =
    useMutation(
      ["readTransferNoteUrl", jobId],
      async () => await instance.jobs.getJobWasteTransferNotePdf(jobId),
      {
        onSuccess: async ({ data }) => {
          if (data && data.location) {
            const blob = await fetch(data.location).then((r) => r.blob());
            const a = document.createElement("a");
            a.href = (window as any).URL.createObjectURL(blob);
            a.download = `wtn-${ref}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        },
        onError: (error: any) => {
          toasts.error({
            message:
              error?.response?.data?.message ||
              "Failed to download the waste transfer note, Try again",
          });
        },
      },
    );

  const { data: vehiclesData } = useQuery(
    ["readVehicles"],
    async () =>
      (
        await instance.vehicles.getVehicles({
          params: {
            limit: 1000,
          },
        })
      ).data,
    {
      // prevent caching to show newly updated values
      cacheTime: 0,
      staleTime: 0,
      enabled: isAdmin,
    },
  );

  const { data: user, isLoading: isUserLoading } = useQuery(
    ["jobUser", jobData?.contactEmail],
    async () =>
      !!jobData?.contactEmail &&
      (await instance.users.getUser(jobData?.contactEmail)).data,
    { retry: false, enabled: !!jobData?.contactEmail && isAdmin },
  );

  const {
    ref,
    date,
    accessInformation,
    addressCity,
    addressCounty,
    addressLine2,
    addressLine1,
    addressPostcode,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    contactNoEmail,
    items: products,
    description,
    images,
    bcId,
    collectionInformation,
    disposalInformation,
    hasCollectionData,
    hasDisposalData,
    plannedStartTime,
    requiredPersons,
    priority,
    assetPool,
    sicCode,
  } = jobData || {};

  const { cubicYardsCollected, estimatedWeight, wasteTypes } =
    collectionInformation || {};

  const { placeOfDisposal, landfillDiversionRate, permitNumber } =
    disposalInformation || {};

  const initialValues: Omit<TEditJob, "assetPool"> & {
    assetPool: { label: string; value: string }[];
  } = {
    ref: ref || "",
    date: dayjs(date).format("DD-MM-YYYY") || "",
    accessInformation: accessInformation || "",
    addressCity: addressCity || "",
    addressCounty: addressCounty || "",
    addressLine1: addressLine1 || "",
    addressLine2: addressLine2 || "",
    addressPostcode: addressPostcode || "",
    contactEmail: contactEmail || "",
    contactFirstName: contactFirstName || "",
    contactLastName: contactLastName || "",
    contactNoEmail: contactNoEmail || false,
    contactPhoneNumber: contactPhoneNumber || "",
    products: products || [],
    description: description || "",
    requiredPersons: requiredPersons || 1,
    ...(isAdmin ? { priority: priority || 1 } : {}),
    assetPool:
      assetPool?.map((value) => ({
        label: value,
        value: value,
      })) || [],
    images: images?.map((val) => val.url) || [],
    bcId: bcId || "",
    collectionInformation: {
      cubicYardsCollected: cubicYardsCollected || undefined,
      estimatedWeight: estimatedWeight || undefined,
      wasteTypes: wasteTypes || "",
    },
    disposalInformation: {
      placeOfDisposal: placeOfDisposal || "",
      landfillDiversionRate: landfillDiversionRate || undefined,
      permitNumber: permitNumber || "",
    },
    sicCode: sicCode || "",
  };

  const { status, companyCode } = jobData || {};

  const { mutate: cancelMutate, isLoading: isLoadingCancel } = useMutation(
    () => instance.jobs.patchJobCancel(jobId?.toString() ?? ""),
    {
      onSuccess: () => {
        history.push("/jobs");
        toasts.success({
          message: t("update.toasts.success"),
        });
      },
      onError: (error) => {
        toasts.error({
          message: getErrorMessage(error),
        });
      },
    },
  );

  /**
   * Refresh WTN
   */
  const { mutate: refreshWTN, isLoading: isRefreshingWTN } = useMutation(
    "refreshWTNBigChange",
    async () => {
      if (!jobId || !ref) return;

      await instance.jobs.postCollectionInformationJobsToSqs([
        {
          id: jobId,
          ref: ref,
        },
      ]);
    },
    {
      onSuccess: () => setShowRefreshWTNModal(true),
      onError: (error) => {
        toasts.error({
          message: getErrorMessage(error),
        });
      },
    },
  );

  const editForm = () => {
    try {
      setEditing(true);
    } catch (e) {
      toasts.error({
        message: "Failed to edit job. Please try again.",
      });
      throw e;
    }
  };

  const handleSubmit = (
    values: Omit<TEditJob, "assetPool"> & {
      assetPool: { label: string; value: string }[];
    },
  ) => {
    const { requiredPersons, priority, assetPool, ...jobValues } = values;
    if (values) {
      const payload = {
        ...jobValues,
        contactPhoneNumber: values.contactPhoneNumber?.replace(/\s/g, ""),
        date: dayjs.utc(values.date, "D/M/YYYY").toISOString(),
        ...(isAdmin && {
          assetPool: !!assetPool
            ? values.assetPool.map(({ value }) => value)
            : [],
          requiredPersons: requiredPersons,
          priority: Number(priority),
        }),
      };
      mutate(payload);
    }
  };

  const { mutate, error, isLoading } = useMutation(
    async (payload: TEditJob) =>
      editJob({
        id: jobId?.toString(),
        ...payload,
      }),

    {
      onSuccess: () => {
        setEditing(false);
        refetchJob();
        toasts.success({
          message: "Changes saved.",
        });
      },
      onError: () => {
        toasts.error({
          message: "Something went wrong. Please try again.",
        });
      },
    },
  );

  return (
    <>
      <Box className="max-w-5xl mx-auto">
        {isLoadingJob || !jobData || !jobId ? (
          <FormLoader isLoading={isLoadingJob} />
        ) : (
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange
          >
            {({ resetForm, values, setFieldError, setFieldValue }) => (
              <>
                {useEffect(() => {
                  setShowSave(true);
                }, [values])}
                <Transition
                  className="mb-4 relative"
                  duration={500}
                  transition="fadeInDown"
                  distance={100}
                  toggle={!isLoadingJob}
                >
                  <UnstyledButton
                    className="flex mb-3 items-center justify-center"
                    onClick={() => history.goBack()}
                  >
                    <Icon.Chevron
                      styles={{ transform: "rotate(180deg)" }}
                      size="small"
                    />
                    {t("modal.buttons.labels.goBack")}
                  </UnstyledButton>
                  <JobDetails
                    id={Number(jobId)}
                    canBookJobAgain={canBookJobAgain}
                    status={status || "Loading"}
                    jobRef={ref || ""}
                    companyCode={companyCode || ""}
                    collection={date || ""}
                    plannedStartTime={plannedStartTime || ""}
                    isFetching={isLoadingJob}
                    jobData={jobData}
                    cancelJob={cancelMutate}
                    isCancelling={isLoadingCancel}
                    editJob={editForm}
                    setBookJobAgainOpen={setBookJobAgainOpen}
                    downloadWTN={mutateTransferNotePdf}
                    isDownloadingWTN={isLoadingTransferNotePdf}
                    refreshWTN={refreshWTN}
                    isRefreshingWTN={isRefreshingWTN}
                  />
                  <Panel shadow={false}>
                    <Box className="grid grid-cols-1 lg:grid-cols-2 gap-x-10">
                      <Box>
                        <Heading color="brand" level={5}>
                          Collection Details
                        </Heading>
                        <Field
                          label={t("update.form.label.addressLine1")}
                          name="addressLine1"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t(
                                "update.form.placeholder.addressLine1",
                              )}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("update.form.label.city")}
                          name="addressCity"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("update.form.placeholder.city")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("update.form.label.county")}
                          name="addressCounty"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t("update.form.placeholder.county")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("update.form.label.postcode")}
                          name="addressPostcode"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled
                              placeholder={t(
                                "update.form.placeholder.postcode",
                              )}
                            />
                          )}
                        </Field>
                        {editing && isAdmin && (
                          <Field
                            name="date"
                            label={t("update.form.label.collectionDate")}
                          >
                            {({ field }) => (
                              <Input.Date
                                disabledDays={[
                                  {
                                    before: new Date(),
                                  },
                                ]}
                                collapsable
                                {...field}
                              />
                            )}
                          </Field>
                        )}
                      </Box>

                      <Box>
                        <Box className="flex">
                          <Heading
                            color="brand"
                            level={5}
                            styles={{ marginRight: "auto" }}
                          >
                            Customer Details
                          </Heading>
                          {isUserLoading && (
                            <Icon.Loading size="small" color="brand" />
                          )}
                          {!isUserLoading && !!user && (
                            <Button
                              onClick={() => {
                                history.push(`/users/update/${user.email}`);
                              }}
                              color="accent"
                              size="xsmall"
                            >
                              {t("buttons.labels.viewUser")}
                            </Button>
                          )}
                        </Box>
                        <Field
                          label={t("update.form.label.firstName")}
                          name="contactFirstName"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={!editing}
                              placeholder={t(
                                "update.form.placeholder.firstName",
                              )}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("update.form.label.lastName")}
                          name="contactLastName"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={!editing}
                              placeholder={t(
                                "update.form.placeholder.lastName",
                              )}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("update.form.label.email")}
                          name="contactEmail"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={!editing}
                              placeholder={t("update.form.placeholder.email")}
                            />
                          )}
                        </Field>

                        <Field
                          label={t("update.form.label.phone")}
                          name="contactPhoneNumber"
                        >
                          {({ field }) => (
                            <Input.Text
                              {...field}
                              disabled={!editing}
                              placeholder={t("update.form.placeholder.phone")}
                            />
                          )}
                        </Field>
                      </Box>
                      <Box className="lg:col-span-2">
                        <Heading color="brand" level={5}>
                          Waste Details
                        </Heading>
                        <Box className="grid lg:grid-cols-2 gap-x-10">
                          <Field
                            label={t("update.form.label.wasteDescription")}
                            name="description"
                          >
                            {({ field }) => (
                              <Input.Textarea
                                {...field}
                                disabled={!editing}
                                maxLength={499}
                                placeholder={t(
                                  "update.form.placeholder.wasteDescription",
                                )}
                              />
                            )}
                          </Field>

                          <Field
                            label={t("update.form.label.accessInformation")}
                            name="accessInformation"
                          >
                            {({ field }) => (
                              <Input.Textarea
                                {...field}
                                disabled={!editing}
                                placeholder={t(
                                  "update.form.placeholder.accessInformation",
                                )}
                              />
                            )}
                          </Field>
                        </Box>
                      </Box>
                      {isClearabeeUser && (
                        <Box className="lg:col-span-2">
                          <Heading color="brand" level={5}>
                            Additional Fields
                          </Heading>
                          <Box className="grid lg:grid-cols-2 gap-x-10">
                            <Field
                              label={t("update.form.label.requiredPersons")}
                              name="requiredPersons"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  type="number"
                                  disabled={!editing || !isAdmin}
                                  placeholder={t(
                                    "update.form.placeholder.requiredPersons",
                                  )}
                                />
                              )}
                            </Field>
                            <Field
                              label={t("update.form.label.priority")}
                              name="priority"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  type="number"
                                  disabled={!editing || !isAdmin}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "priority",
                                      event.target.value,
                                    );
                                    if (+event.target.value > priorityLimit) {
                                      setFieldError(
                                        "priority",
                                        "Priority must be less than or equal to " +
                                          priorityLimit,
                                      );
                                    }
                                  }}
                                  placeholder={t(
                                    "update.form.placeholder.priority",
                                  )}
                                />
                              )}
                            </Field>
                          </Box>
                          <Box className="grid z-100 lg:grid-cols-2 gap-x-10">
                            <Field
                              label={t("update.form.label.assetPool")}
                              name="assetPool"
                            >
                              {({ field }) => (
                                <Select
                                  {...field}
                                  isDisabled={!editing || !isAdmin}
                                  placeholder={t(
                                    "update.form.placeholder.assetPool",
                                  )}
                                  name="assetPool"
                                  multiple
                                  closeMenuOnSelect={false}
                                  isMulti
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  options={vehiclesData?.items.map(
                                    (vehicle) => ({
                                      label: vehicle.registration,
                                      value: vehicle.registration,
                                    }),
                                  )}
                                />
                              )}
                            </Field>
                            <Field
                              name="sicCode"
                              label={t("update.form.label.sicCode")}
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled={!editing}
                                  placeholder={t(
                                    "update.form.placeholder.sicCode",
                                  )}
                                />
                              )}
                            </Field>
                          </Box>
                        </Box>
                      )}
                      {(hasDisposalData || hasCollectionData) && (
                        <Box className="lg:col-span-2">
                          <Heading color="brand" level={5}>
                            {t("headings.completedJobDetails")}
                          </Heading>
                          <Box className="grid lg:grid-cols-2 gap-x-10">
                            <Field
                              label={t("form.label.weight")}
                              name="collectionInformation.estimatedWeight"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled
                                  placeholder={t(
                                    "form.placeholder.notYetAdded",
                                  )}
                                />
                              )}
                            </Field>

                            <Field
                              label={t("form.label.volumeCubicYards")}
                              name="collectionInformation.cubicYardsCollected"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled
                                  placeholder={t(
                                    "form.placeholder.notYetAdded",
                                  )}
                                />
                              )}
                            </Field>

                            <Field
                              label={t("form.label.placeOfDisposal")}
                              name="disposalInformation.placeOfDisposal"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled
                                  placeholder={t(
                                    "form.placeholder.notYetAdded",
                                  )}
                                />
                              )}
                            </Field>

                            <Field
                              label={t("form.label.permitNumber")}
                              name="disposalInformation.permitNumber"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled
                                  placeholder={t(
                                    "form.placeholder.notYetAdded",
                                  )}
                                />
                              )}
                            </Field>

                            <Field
                              label={t("form.label.wasteTypes")}
                              name="collectionInformation.wasteTypes"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled
                                  placeholder={t(
                                    "form.placeholder.notYetAdded",
                                  )}
                                />
                              )}
                            </Field>

                            <Field
                              label={t("form.label.landfillDiversionRate")}
                              name="disposalInformation.landfillDiversionRate"
                            >
                              {({ field }) => (
                                <Input.Text
                                  {...field}
                                  disabled
                                  placeholder={t(
                                    "form.placeholder.notYetAdded",
                                  )}
                                />
                              )}
                            </Field>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Panel>

                  {!hidePhotos && (
                    <JobImages
                      jobRef={jobData?.ref}
                      jobImages={jobData.images || []}
                      onImagesChange={() => refetchJob()}
                    />
                  )}
                </Transition>

                <Transition
                  className="flex justify-center mb-6"
                  duration={500}
                  transition="fadeInUp"
                  distance={100}
                  toggle={editing}
                >
                  <Button
                    name="save"
                    size="small"
                    type="submit"
                    color="accent"
                    disabled={!showSave || isLoading}
                    className="flex justify-center gap-x-2 mr-3"
                  >
                    {t("buttons.labels.save")}
                    {isLoading && <Icon.Loading size="small" />}
                  </Button>

                  {editing && (
                    <Button
                      name="cancel"
                      type="button"
                      color="negative"
                      size="small"
                      disabled={isLoading}
                      onClick={() => {
                        resetForm();
                        setShowSave(false);
                        setEditing(false);
                      }}
                    >
                      {t("buttons.labels.cancelEditing")}
                    </Button>
                  )}
                </Transition>
              </>
            )}
          </Form>
        )}
        {displayErrorMessage(error, ({ children }) => (
          <Box className="flex items-center justify-center w-full mb-4 overflow-scroll">
            <Message type="error" background>
              {children}
            </Message>
          </Box>
        ))}

        {!isLoadingJob && !!jobData && (
          <AdditionalInfo
            data={jobData}
            setBookJobAgainOpen={setBookJobAgainOpen}
            canBookJobAgain={canBookJobAgain}
            additionalActions={
              !jobData.tipId && isAdmin ? (
                <AllocateTip
                  jobRef={jobData.ref}
                  id={String(jobData.id)}
                  onSuccess={() => refetchJob()}
                />
              ) : (
                <></>
              )
            }
          />
        )}

        {!!jobData && (
          <BookJobAgain
            jobData={jobData}
            isOpen={bookJobAgainOpen}
            setIsOpen={setBookJobAgainOpen}
          />
        )}

        {!!jobData && !!jobData.ref && (isAdmin || isClearabeeUser) && (
          <WorksheetsAnswers jobRef={jobData.ref ?? ""} />
        )}

        {!!jobData && !!jobData.tip && (isAdmin || isClearabeeUser) && (
          <TipDetails tip={jobData?.tip} />
        )}

        {!isLoadingJobReports &&
          !!jobReports &&
          !!jobReports.length &&
          (isAdmin || isClearabeeUser) && (
            <ReportDetails reports={jobReports} />
          )}
      </Box>
      {/* REFRESH WTN MODAL */}
      <Modal
        modalVisible={showRefreshWTNModal}
        width={500}
        styles={{
          padding: `${theme.spacing.small} ${theme.spacing.large}`,
          [`@media (min-width: ${theme.screens.medium})`]: {
            padding: theme.spacing.large,
          },
        }}
      >
        <Box className="flex flex-col items-center gap-y-5">
          <Heading level={3} color="brand">
            {t("update.modal.wasteTransferNotes")}
          </Heading>
          <Text>{t("update.infoRefreshWTN")}</Text>
          <Button
            size="small"
            color="warning"
            onClick={() => setShowRefreshWTNModal(false)}
          >
            {t("update.buttons.close")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
