"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.common = void 0;
exports.common = {
    emailAddress: "Email Address",
    password: "Password",
    login: "Sign in",
    logout: "Sign out",
    back: "Back",
    backTo: "Back to {{location}}",
    requiredField: "This field is required",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    total: "Total",
    nonApplicable: "N/A",
    close: "Close",
    none: "None",
    reference: "Reference",
    form: {
        buttons: {
            search: "Search",
            reset: "Reset",
            view: "View",
            remove: "Remove",
        },
        labels: {
            pageSize: "Rows per page:",
            what3Words: "What 3 Words",
            postcode: "Postcode*",
            addressChoices: "Address*",
            addressLine1: "Address Line 1*",
            addressLine2: "Address Line 2",
            city: "City*",
            county: "County",
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email Address",
            phoneNumber: "Phone Number",
            serviceType: "Service Type",
            serviceProduct: "Service Product",
            price: "Price",
            time: "Time",
            comments: "Supporting comments",
            date: "Date",
            timeslot: "Timeslot",
            wasteDescription: "Waste Description",
            accessInfo: "Access information",
            noEmail: "No email address available",
            uploadImages: "Upload images",
            qty: "Quantity",
            approved: "Approved on behalf of",
            validUntil: "Valid Until",
        },
        placeholders: {
            postcode: "Enter your postcode",
            addressChoices: "Select your address",
            addressChoicesDisabled: "Enter your postcode first",
            addressLine1: "Number and Road Name",
            addressLine2: "Address Line 2",
            city: "City",
            county: "County",
            firstName: "First name",
            lastName: "Last name",
            email: "Email address",
            phoneNumber: "Phone number",
            serviceType: "Service",
            serviceProduct: "Product",
            price: "Price",
            time: "Time",
            comments: "Comments",
            date: "Collection Date",
            dateOnly: "Date",
            timeslot: "Timeslot",
            wasteDescription: "Waste Description",
            what3Words: "Enter what3words here",
            accessInfo: "Access information",
            searchCustomer: "Type to search customer",
        },
        titles: {
            bookJob: "Book a new job",
            createNewJob: "Create a new job:",
            bookAdhoc: "Book ADHOC job",
            bookAdhocSubtitle: "Book a new adhoc job on behalf of a user.",
            getANewQuote: "Get a new quote",
            viewQuotes: "View all quotes",
            takePayment: "Take additional payment",
            takePaymentSubtitle: "Take an additional payment against an existing job.",
            leaderboard: "Leaderboard",
            leaderboardSubtitle: "View stats and daily, weekly and monthly leaders.",
            manageUsers: "Manage users",
            manageUsersSubtitle: "View and edit users within your company.",
            manageVehicles: "Manage Vehicles",
            manageVehiclesSubtitle: "Add, remove and edit vehicles - plus assign jobs.",
            manageUserRoles: "Manage user roles",
            manageUserRolesSubtitle: "Add, remove and edit user permissions.",
            managePrices: "Manage prices",
            managePricesSubtitle: "Add, remove and edit prices.",
            manageCompanies: "Manage companies",
            manageCompaniesSubtitle: "View and edit company details.",
            manageProducts: "Manage products",
            manageProductsSubtitle: "Add, remove and edit products.",
            manageCallout: "Manage call out charges",
            manageCalloutSubtitle: "Add, remove and edit call out charges.",
            manageCutoffs: "Manage cut off times & timeslots",
            manageCutoffsSubtitle: "Add, remove and edit cut off times and timeslots.",
            manageDateBlackout: "Manage date blackouts",
            manageDateBlackoutSubtitle: "Add, remove and edit date blackouts.",
            managePostcodeBlackout: "Manage postcode blackouts",
            managePostcodeBlackoutSubtitle: "Add, remove and edit postcode blackouts.",
            manageProductOptions: "Manage product options",
            manageProductOptionsSubtitle: "Add, remove and edit product options.",
            manageServices: "Manage services",
            manageServicesSubtitle: "Add, remove and edit services.",
            recentJobs: "Recent Jobs",
            takeAdditionalPayment: "Take additional payment",
        },
        buttonLabels: {
            uploadLogo: "Upload logo",
        },
        loading: "Bee with you in a moment",
        loading2: "Won't bee long now",
        filter: {
            labels: {
                filterBy: "Filter by:",
            },
        },
        fourOOne: {
            heading: "Access Denied",
            text: "Sorry you do not have permission to access that.",
            buttons: {
                back: "Back to Dashboard",
            },
        },
        errors: {
            requiredFieldShort: "Required",
            requiredField: "This field is required",
            validPostcode: "Postcode is invalid",
            validLettersOnly: "Must contain letters only",
            validEmail: "Email is invalid",
            validPhone: "Phone number invalid",
            validNumber: "This field must be a number",
            validPrice: "Price is invalid",
            validEmailOrPhone: "Email or phone required",
            validIncludeLetters: "Must include letters",
        },
    },
    head: {
        title: "Clearabee Portal",
    },
    titles: {
        imageDeleted: "The image has been deleted successfully",
        confirmDeleteImage: "Are you sure you want to delete the image?",
        bookJob: "Book a Job",
        createNewJob: "Create a new job:",
        bookMultipleJobs: "Book Multiple Jobs",
        find3Words: "What 3 words is a simplified form of map coordinates, follow the link below to find your 3 words",
        bookAdhoc: "Book ADHOC Job",
        bookAdhocSubtitle: "Book a new adhoc job on behalf of a user.",
        getANewQuote: "Get a New Quote",
        viewQuotes: "View All Quotes",
        takePayment: "Take Additional Payment",
        takePaymentSubtitle: "Take an additional payment against an existing job.",
        leaderboard: "Leaderboard",
        leaderboardSubtitle: "View stats and daily, weekly and monthly leaders.",
        manageUsers: "Manage Users",
        manageUsersSubtitle: "Add, remove and edit users.",
        manageVehicles: "Manage Vehicles",
        manageVehiclesSubtitle: "Add, remove and edit vehicles - plus assign jobs.",
        manageUserRoles: "Manage user roles",
        manageUserRolesSubtitle: "Add, remove and edit user permissions.",
        managePrices: "Manage prices",
        managePricesSubtitle: "Add, remove and edit prices.",
        manageCompanies: "Manage Companies",
        manageCompaniesSubtitle: "Add, remove and edit companies.",
        manageCatalogues: "Manage Catalogues",
        manageProducts: "Manage products",
        manageProductsSubtitle: "Add, remove and edit products.",
        manageCallout: "Manage call out charges",
        manageCalloutSubtitle: "Add, remove and edit call out charges.",
        manageCutoffs: "Manage cut off times & timeslots",
        manageCutoffsSubtitle: "Add, remove and edit cut off times and timeslots.",
        manageDateBlackout: "Manage date blackouts",
        manageDateBlackoutSubtitle: "Add, remove and edit date blackouts.",
        managePostcodeBlackout: "Manage postcode blackouts",
        managePostcodeBlackoutSubtitle: "Add, remove and edit postcode blackouts.",
        manageProductOptions: "Manage product options",
        manageProductOptionsSubtitle: "Add, remove and edit product options.",
        manageServices: "Manage services",
        manageServicesSubtitle: "Add, remove and edit services.",
        totalOrders: "Total Orders",
        paymentLinks: "Payment Links",
        phonePaymentsToday: "Phone Payments Today",
        phoneBookings: "Phone Bookings",
        recentJobs: "Recent Jobs",
        orderValueTotal: "Order Value Total",
    },
    buttonLabels: {
        uploadLogo: "Upload logo",
        getStarted: "Get Started",
        find3Words: "Find your 3 words",
    },
    loading: "Bee with you in a moment",
    loading2: "Won't bee long now",
    filter: {
        labels: {
            filterBy: "Filter by:",
        },
    },
    routes: {
        auth: "Auth",
        "auth/login": "Login",
        "auth/forgot": "Forgot Password",
        "auth/reset": "Reset",
        "auth/welcome": "Welcome",
        companySignup: "Company Signup",
        dashboard: "Dashboard",
        "jobs/create": "Book Job",
        "jobs/create/adhoc": "Book ADHOC Job",
        "jobs/create/commercial": "Book A Job",
        "jobs/update": "Edit Job",
        "jobs/task": "Task",
        garages: "Garages",
        "jobs/schedule": "Schedule Jobs",
        "jobs/schedule/view": "View Schedule",
        "jobs/schedule/map": "View Map",
        "drivers/jobs": "Driver Jobs",
        "drivers/jobs/allocate": "Allocate Jobs",
        "drivers/jobs/compare": "Compare Driver Jobs",
        "drivers/notifications": "Driver Notifications",
        "drivers/allocate": "Driver Allocation",
        "drivers/create": "Create Driver",
        "drivers/preferences": "Drivers Preferences",
        jobs: "Jobs",
        "jobs/review": "Review Jobs",
        "jobs/payment": "Take Additional Payment",
        "jobs/book-multiple": "Book Multiple Jobs",
        "jobs/payment-links": "Payment Links",
        "jobs/payment-links/update": "Update Payment Link",
        "jobs/failed": "Failed Jobs",
        "jobs/pending": "Pending Jobs",
        "jobs/missing-tip": "Jobs Missing Tip",
        "baskets/pending": "Pending Baskets",
        "jobs/pending/approve": "Approve",
        "jobs/quick-sync": "Quick Sync",
        "vehicles/select": "Select Vehicle",
        "vehicles/create": "Create Vehicle",
        "vehicles/update": "Update Vehicle",
        "vehicles/assign": "Assign Jobs To Vehicle",
        "vehicles/jobs": "Vehicle Jobs",
        vehicles: "Manage Vehicles",
        "vehicles/approve": "Approve Vehicles",
        "vehicles/schedule": "Schedule",
        "vehicles/preferences": "Vehicle Preferences",
        users: "Users",
        "users/create": "Create User",
        "users/update": "Edit User",
        subcontractors: "Subcontractors",
        "subcontractors/update": "Update Subcontractor",
        "subcontractors/create": "Create Subcontractor",
        "subcontractors/children": "Subcontractor Users",
        "subcontractors/jobs": "Subcontractor Jobs",
        "subcontractors/jobs/compare": "Compare Subcontractor Jobs",
        "subcontractors/bulk-allocation": "Bulk Allocation",
        "subcontractors/bulk-allocation-legacy": "Bulk Allocation (Legacy)",
        "subcontractors/approve": "Approve Subcontractors",
        "subcontractors/tips": "Manage Tips",
        "subcontractors/tips/approve": "Approve Tips",
        notifications: "Notifications",
        "notifications/update": "Update Notification",
        "notifications/create": "Create Notification",
        reports: "Reports",
        "reports/leaderboard": "Leaderboard",
        "reports/dashboard": "Reporting Dashboard",
        "reports/statistics": "Statistics",
        "reports/scheduled": "Scheduled Reports",
        worksheets: "Worksheets",
        "worksheets/create": "Create Worksheet",
        "worksheets/update": "Update Worksheet",
        "worksheets/order-type-values": "Order Type Values",
        "worksheets/arrange": "Arrange Worksheets",
        quotes: "Quotes",
        "quotes/create": "Get a Quote",
        "quotes/update": "Update Quote",
        "companies/update": "Update Company",
        "companies/create": "Create Company",
        companies: "Companies",
        "catalogues/templates/update": "Update Product Template",
        "catalogues/templates/cascade": "Cascade Product Template",
        "catalogues/templates/create": "Create Product Template",
        "catalogues/templates": "Product Templates",
        catalogues: "Catalogues",
        "catalogues/update": "Update Catalogue",
        "catalogues/update/item": "Update Catalogue Item",
        "catalogues/create": "Create Catalogue",
        "catalogues/blackout": "Blackout Dates/Days",
        "catalogues/order-type-ids": "Order Type IDs",
        "catalogues/zones": "Zones",
        "vehicles/approval": "Vehicles Approval",
        permissions: "Permissions",
        roles: "Roles",
        "roles/create": "Create Role",
        "roles/update": "Update Role",
        suppliers: "Suppliers",
        "suppliers/prices": "View Supplier Prices",
    },
    fourOOne: {
        heading: "Access Denied",
        text: "Sorry you do not have permission to access that.",
        buttons: {
            back: "Back to Dashboard",
        },
    },
    postcodeLookup: {
        switchToLookup: "Switch back to postcode lookup",
        cantFind: "Can’t find your address?",
        switchToManual: "Enter address manually",
    },
};
