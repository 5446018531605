import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { FormikContextType, useFormikContext, getIn } from "formik";
import { instance, Address, PostBasketResponse } from "@clearabee/ui-sdk";
import { IBasketBody } from "@clearabee/api-schemas";
import {
  Icon,
  Input,
  Table,
  Field,
  formatCurrency,
  Text,
  theme,
  Panel,
  Box,
  useModal,
  Button,
} from "@clearabee/ui-library";
import { processBasketForInvoiceCustomer, readAddresses } from "api";
import { getCalloutCharge } from "api/catalogues";
import { InitialValues } from "../validation";
import { postcodeRegExp } from "validation/common";
import { useAuthContext, useCatalogueContext, useDebounce } from "hooks";
import roles from "constants/roles";
import { filterByBlackoutDate } from "helpers/catalogue";
import { DeleteModal } from "./deleteModal";
import { FailModal } from "./failModal";
import Warning from "../../../../images/warning.svg";

dayjs.extend(utc);

interface NewJobRowProps {
  index: number;
  paymentIndex: number;
  finishSubmit: (basket: PostBasketResponse | undefined) => void;
  companyCode: string;
  isLoading: boolean;
  catalogueId: string;
  emailRequired?: boolean;
  poNumberLabel?: string;
}

const getAddressFromString = (address: string, postcode: string) => {
  const json: Address & { lat: number | null; lng: number | null } =
    JSON.parse(address);

  return {
    line1: json.line_1,
    line2: json.line_2,
    county: json.county,
    city: json.town_or_city,
    postcode,
    lat: json.lat,
    lng: json.lng,
  };
};

export const NewJobRow = ({
  index,
  paymentIndex,
  finishSubmit,
  companyCode,
  catalogueId,
  isLoading: isLoadingGlobal,
  emailRequired,
  poNumberLabel,
}: NewJobRowProps): React.ReactElement | null => {
  const [translate] = useTranslation("jobs");

  // Hooks
  const {
    items,
    isLoading: isLoadingCatalogue,
    setCompanyCode,
  } = useCatalogueContext();
  const { doesUserHaveRole, getCurrentUserCurrentCompanySettings } =
    useAuthContext();
  const {
    values,
    setValues,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  }: FormikContextType<InitialValues> = useFormikContext();

  // States
  const [poToCheck, setPoToCheck] = useState({ poNumber: "", postcode: "" });
  const [adjustQuantity, setAdjustQuantity] = useState(false);
  const [limitErrorShown, setLimitErrorShown] = useState(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [basketBody, setBasketBody] = useState<IBasketBody | undefined>(
    undefined,
  );

  // Modals
  const [LimitModal, setIsLimitModalVisible] = useModal();
  const [DuplicateModal, setIsDuplicateModalVisible] = useModal();

  const worksForClearabee = doesUserHaveRole([
    roles.CLEARABEE_ADMIN,
    roles.CLEARABEE_CUSTOMER_SERVICE,
  ]);

  const settings = getCurrentUserCurrentCompanySettings();

  const debouncedPoValue = useDebounce(poToCheck.poNumber, 2000);

  const debouncedPostcodeValue = useDebounce(poToCheck.postcode, 2000);

  const {
    postcode,
    address,
    date,
    product,
    poNumber,
    description,
    noContactDetails,
    name,
    email,
    contact,
    bigchangeProps,
  } = values.rows[index];

  /**
   * check if the company code is for LV
   */
  const isCompanyCodeLV = !!companyCode && companyCode.includes("LV-");

  /**
   * check if the risk address is different to the collection address
   */
  const isRiskAddressDifferentToCollectionAddress =
    bigchangeProps.cust_RiskAddressIsCollectionAddress;

  /**
   * get address by postcode
   */
  const { data: addressData } = useQuery(
    ["getAddressByPostcode", postcode],
    () => readAddresses(postcode),
    {
      retry: false,
      enabled: !!postcode.match(postcodeRegExp) || postcode.length > 4,
    },
  );

  /**
   * post basket mutation
   */
  const {
    data: basketData,
    mutate: createBasket,
    isLoading: isLoadingCreateBasket,
    isSuccess: isSuccessCreateBasket,
  } = useMutation(
    "createBasket",
    async () => {
      if (!basketBody) return;

      return (
        await instance.catalogues.postBasket({
          ...basketBody,
          date: basketBody?.date?.toISOString(),
        })
      ).data;
    },
    {
      onError: (error: Error) => {
        if (error.message.includes("limit for bookings") && !limitErrorShown) {
          setIsLimitModalVisible(true);
          setLimitErrorShown(true);
        }
      },
    },
  );

  /**
   * post basket invoice customer mutation
   */
  const {
    mutate: postBasketInvoiceCustomer,
    isLoading: isLoadingPostBasketInvoiceCustomer,
    isSuccess: isSuccessPostBasketInvoiceCustomer,
    isError: isErrorPostBasketInvoiceCustomer,
  } = useMutation(
    async () => {
      if (!basketData) return;

      await processBasketForInvoiceCustomer(basketData.basketToken, poNumber);
    },
    {
      onSuccess: () => {
        finishSubmit(basketData);
      },
    },
  );

  /**
   * get jobs by purchase order to check if the purchase order already exists
   */
  const { isLoading: jobsIsLoading } = useQuery(
    ["getJobs", debouncedPoValue, debouncedPostcodeValue],
    async () =>
      (
        await instance.jobs.getJobs({
          params: {
            limit: 1,
            "companyCode:in": companyCode,
            "addressPostcode:eq": poToCheck.postcode.replaceAll(" ", ""),
            "purchaseOrder:in":
              poToCheck.poNumber || Number(poToCheck.poNumber),
          },
        })
      ).data.items,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!debouncedPoValue,
      onSuccess: (data) => {
        if (!!data && !!data.length) {
          setIsDuplicateModalVisible(true);
        }
      },
    },
  );

  /**
   * funcs to delete the row
   */
  const deleteRow = (): void => {
    setFieldTouched(`rows[${index}]`, false);
    setValues({
      ...values,
      rows: values.rows.filter((_, i) => i !== index),
    });
  };

  /**
   * func to duplicate the row
   */
  const duplicateRow = (): void => {
    const selectedRow = values.rows.filter((_, i) => i == index);
    setValues({
      ...values,
      rows: [...values.rows, ...selectedRow],
    });
  };

  /**
   * handle the basket item
   */
  const handleBasketItem = async (): Promise<void> => {
    if (!postcode || !address || !date || !product) return;

    setIsLoadingPrice(true);
    if (!bigchangeProps.cust_RiskAddressIsCollectionAddress) {
      bigchangeProps.cust_RiskPostcode = "";
    }

    const customFields = Object.entries(bigchangeProps)
      .map(([key, value]) => {
        if (
          isCompanyCodeLV &&
          (key === "cust_RiskAddressIsCollectionAddress" ||
            key === "cust_RiskPostcode")
        ) {
          return { [key]: String(value) };
        }
      })
      .filter(Boolean) as Array<Record<string, string>>;

    const items = product.flatMap(({ sku, qty, price }) => ({
      sku,
      qty: Number(qty),
      price,
    }));

    if (!!items.length && catalogueId !== "PORTAL") {
      try {
        const calloutCharge = await getCalloutCharge(
          items[0]?.sku,
          catalogueId,
        );

        if (!!calloutCharge) {
          items.push({
            sku: calloutCharge.sku,
            price: calloutCharge.price || 0,
            qty: 1,
          });
        }
      } catch {
        // do nothing
      }
    }

    const body: IBasketBody = {
      catalogueId,
      deliveryAddress: getAddressFromString(address, postcode),
      date: dayjs.utc(date, "DD/MM/YYYY").toDate(),
      items,
      description,
      companyCode,
      ...(noContactDetails
        ? { contact: null }
        : {
            contact: {
              phoneNumber: contact,
              firstName: name,
              lastName: name,
              email,
            },
          }),
      customFields,
      meta: {
        page: "Book Multiple Jobs",
      },
    };

    setBasketBody(body);
    setIsLoadingPrice(false);
  };

  /**
   * Address options for the select input
   */
  const addressOptions = useMemo(() => {
    if (!addressData || !addressData?.addresses) return [];

    return addressData.addresses.map((choice) => {
      const { line_1: line1, line_2: line2, town_or_city: city } = choice;
      return {
        label: line2 ? `${line1}, ${line2}, ${city}` : `${line1}, ${city}`,
        value: JSON.stringify({
          ...choice,
          lat: addressData.latitude || null,
          lng: addressData.longitude || null,
        }),
      };
    });
  }, [addressData?.addresses]);

  /**
   * Item options for the select input
   */
  const itemOptions = useMemo(() => {
    if (!items || !date) return [];

    const itemsFilteredByDate = filterByBlackoutDate(
      items,
      dayjs.utc(date, "D/M/YYYY").toISOString(),
      postcode,
    );

    const parsedItems = itemsFilteredByDate.map((item) => {
      const { title, sku, parentSku, price } = item;
      return {
        label: title,
        value: sku,
        qty: 1,
        sku,
        parentSku: parentSku || "",
        price: price || 0,
      };
    });

    if (catalogueId === "PORTAL" || !product?.length) {
      return parsedItems;
    }

    return parsedItems.filter((item) => {
      return product[0].parentSku === item.parentSku;
    });
  }, [items, date, product, catalogueId]);

  /**
   * Total cost of the basket
   */
  const totalCost = useMemo(() => {
    if (!basketBody || !basketBody?.items) return 0;

    return basketBody.items
      .map((item) => (item?.price ?? 0) * item.qty)
      .reduce((acc, curr) => acc + curr, 0);
  }, [JSON.stringify(basketBody?.items)]);

  /**
   * this effect is used to reset the contact details when the no contact details is checked
   */
  useEffect(() => {
    if (noContactDetails === true) {
      setFieldValue(`rows[${index}].name`, "");
      setFieldTouched(`rows[${index}].name`, false);
      setFieldValue(`rows[${index}].contact`, "");
      setFieldTouched(`rows[${index}].contact`, false);
      setFieldValue(`rows[${index}].email`, "");
      setFieldTouched(`rows[${index}].email`, false);
    }
  }, [noContactDetails]);

  /**
   * this effect is used to reset the company code when the catalogue id is changed
   */
  useEffect(() => {
    if (catalogueId === "PORTAL") {
      setCompanyCode("");
    }
  }, [catalogueId]);

  /**
   * this effect is used to create a basket when the form is submitting
   */
  useEffect(() => {
    if (isSubmitting && index === paymentIndex) {
      createBasket();
    }
  }, [isSubmitting, index, paymentIndex]);

  /**
   * this effect is used to create an invoice when the basket is created
   */
  useEffect(() => {
    if (!isSuccessCreateBasket) return;

    postBasketInvoiceCustomer();
  }, [isSuccessCreateBasket]);

  /**
   * this effect is used to update the basket body state
   */
  useEffect(() => {
    handleBasketItem();
  }, [
    postcode,
    address,
    date,
    product,
    noContactDetails,
    bigchangeProps.cust_RiskAddressIsCollectionAddress,
  ]);

  const isLoading =
    isLoadingCreateBasket ||
    isLoadingCatalogue ||
    isLoadingGlobal ||
    isLoadingPostBasketInvoiceCustomer ||
    isSuccessPostBasketInvoiceCustomer ||
    isErrorPostBasketInvoiceCustomer;

  return (
    <>
      <Table.Row>
        <Table.Cell>
          {isLoadingPostBasketInvoiceCustomer && <Icon.Loading color="brand" />}
          {isSuccessPostBasketInvoiceCustomer && (
            <Icon.Checkbox size="medium" color="accent.dark" />
          )}
          {isErrorPostBasketInvoiceCustomer && (
            <Icon.Close size="medium" color="negative" />
          )}
        </Table.Cell>
        <Table.Cell>
          <Box style={{ display: "flex", gap: theme.spacing.small }}>
            {/* POSTCODE */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].postcode`}
              label={translate("table.headings.postcode")}
              styles={{ flex: 1 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  placeholder={translate("table.headings.postcode")}
                  disabled={isLoading}
                  onChange={(event) => {
                    setPoToCheck({
                      postcode: event.target.value,
                      poNumber: getIn(
                        values,
                        `rows[${index}].poNumber`,
                        poNumber,
                      ),
                    });
                    field.onChange(event);
                  }}
                />
              )}
            </Field>
            {/* ADDRESS */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].address`}
              label={translate("table.headings.address")}
              styles={{ flex: 1 }}
            >
              {({ field }) => (
                <Input.Select
                  placeholder={translate("table.headings.address")}
                  disabled={isLoading || !addressOptions.length || !postcode}
                  options={addressOptions}
                  isSearchable
                  defaultValue={address}
                  {...field}
                />
              )}
            </Field>
            {/* DATE */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].date`}
              label={translate("table.headings.jobDate")}
              styles={{ flex: 1 }}
            >
              {({ field }) => (
                <Input.Date
                  {...field}
                  onChange={(event) => {
                    setFieldValue(`rows[${index}].product`, "");

                    const value = event.target.value;

                    if (!dayjs(value, "DD/MM/YYYY").isValid()) {
                      field.onChange("");
                      return;
                    }

                    field.onChange(event);
                  }}
                  disabled={isLoading || !addressOptions.length || !address}
                  initialValue={field.value ?? ""}
                  collapsable
                  disabledDays={[
                    {
                      before: new Date(),
                    },
                    { daysOfWeek: [0] },
                  ]}
                  placeholder={translate("table.headings.jobDate")}
                />
              )}
            </Field>
            {/* PRODUCT */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].product`}
              label={translate("table.headings.item")}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Select
                  {...field}
                  isSearchable
                  isClearable
                  isMulti
                  isDisabled={
                    isLoading ||
                    !addressOptions.length ||
                    !itemOptions.length ||
                    !address ||
                    !date
                  }
                  options={itemOptions}
                  onChange={(value) => field.onChange(value || [])}
                  placeholder={translate("table.headings.item")}
                  css={{ width: "100%" }}
                />
              )}
            </Field>
            {/* NO CONTACT DETAILS */}
            {!emailRequired ? (
              <Field
                name={`rows[${index}].noContactDetails`}
                label={translate("table.headings.noContactDetails")}
                styles={{ flex: 1 }}
              >
                {({ field }) => (
                  <Input.Toggle
                    {...field}
                    disabled={isLoading}
                    styles={{
                      marginTop: "12px",
                      opacity: isLoading ? 0.5 : 1,
                    }}
                    defaultChecked={values.rows[index].noContactDetails}
                  />
                )}
              </Field>
            ) : (
              <Box />
            )}
          </Box>
          <Box style={{ display: "flex", gap: theme.spacing.small }}>
            {/* DESCRIPTION */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].description`}
              label={translate("table.headings.description")}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Textarea
                  {...field}
                  autoGrow
                  disabled={isLoading}
                  onBlur={(event) => {
                    field.onBlur(event);
                    handleBasketItem();
                  }}
                  placeholder={translate("table.headings.description")}
                />
              )}
            </Field>
            {/* CONTACT NAME */}
            <Field
              name={`rows[${index}].name`}
              messageStyles={{ position: "absolute" }}
              required={!noContactDetails}
              label={translate("table.headings.name")}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isLoading || noContactDetails}
                  placeholder={translate("table.headings.name")}
                />
              )}
            </Field>
            {/* CONTACT PHONE */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].contact`}
              label={translate("table.headings.contactPhone")}
              required={!noContactDetails}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isLoading || noContactDetails}
                  placeholder={translate(
                    "table.headings.internationalContactPhone",
                  )}
                  onBlur={(event) => {
                    field.onBlur(event);
                    handleBasketItem();
                  }}
                />
              )}
            </Field>
            {/* EMAIL */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].email`}
              label={translate("table.headings.email")}
              required={!noContactDetails}
              styles={{ flex: 2 }}
            >
              {({ field }) => (
                <Input.Text
                  {...field}
                  disabled={isLoading || noContactDetails}
                  placeholder={translate("table.headings.email")}
                  onBlur={(event) => {
                    field.onBlur(event);
                    handleBasketItem();
                  }}
                />
              )}
            </Field>
            {/* PURCHASE ORDER NUMBER */}
            <Field
              messageStyles={{ position: "absolute" }}
              name={`rows[${index}].poNumber`}
              styles={{ flex: 2 }}
              label={translate("table.headings.poNumber")}
            >
              {({ field }) => (
                <Box className="flex flex-row items-center justify-between gap-3">
                  <Box className="w-4/5">
                    <Input.Text
                      {...field}
                      placeholder={
                        poNumberLabel ?? translate("table.headings.poNumber")
                      }
                      disabled={isLoading}
                      onChange={(event) => {
                        setPoToCheck({
                          poNumber: event.target.value,
                          postcode: getIn(
                            values,
                            `rows[${index}].postcode`,
                            postcode,
                          ),
                        });
                        field.onChange(event);
                      }}
                      onBlur={(event) => {
                        field.onBlur(event);
                        handleBasketItem();
                      }}
                    />
                  </Box>
                  <Box className="w-1/5">
                    {jobsIsLoading && (
                      <Icon.Loading color="brand" size="medium" />
                    )}
                  </Box>
                </Box>
              )}
            </Field>
            {/* PRICE */}
            <Box className="relative top-4">
              <Text fontSize="base" className="font-semibold pb-2">
                {translate("table.headings.price")}
              </Text>
              {isLoadingPrice && <Icon.Loading color="brand" size="medium" />}
              {!!basketBody &&
                !!postcode &&
                !!address &&
                !!date &&
                !!product &&
                !isLoadingPrice &&
                (!settings?.hidePrices || worksForClearabee) && (
                  <Text>{formatCurrency(totalCost)}</Text>
                )}
            </Box>
          </Box>

          {isCompanyCodeLV && (
            <Box className="flex justify-start gap-4">
              <Field
                name={`rows[${index}].bigchangeProps.cust_RiskAddressIsCollectionAddress`}
                messageStyles={{ position: "absolute" }}
                label={translate(
                  "table.headings.isRiskAddressDifferentToCollectionAddress",
                )}
              >
                {({ field }) => (
                  <Input.Toggle
                    {...field}
                    disabled={isLoading}
                    styles={{
                      opacity: isLoading ? 0.5 : 1,
                    }}
                  />
                )}
              </Field>

              {isRiskAddressDifferentToCollectionAddress && (
                <Field
                  name={`rows[${index}].bigchangeProps.cust_RiskPostcode`}
                  messageStyles={{ position: "absolute" }}
                  required={isRiskAddressDifferentToCollectionAddress}
                  label={translate("table.headings.riskPostcode")}
                >
                  {({ field }) => (
                    <Input.Text
                      {...field}
                      disabled={isLoading}
                      placeholder={translate("table.headings.riskPostcode")}
                      onBlur={(event) => {
                        field.onBlur(event);
                        handleBasketItem();
                      }}
                    />
                  )}
                </Field>
              )}
            </Box>
          )}

          {adjustQuantity && !!product?.length && (
            <Panel
              styles={{
                padding: `${theme.spacing.small} ${theme.spacing.large}`,
                maxWidth: theme.screens.small,
                margin: `${theme.spacing.small} auto`,
              }}
            >
              {product?.flatMap((item, ind) => (
                <Field
                  name={`rows[${index}]['product'][${ind}].qty`}
                  styles={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {({ field }) => (
                    <>
                      <Text>{item.label}</Text>
                      <Input.Quantity
                        {...field}
                        disabled={isLoading}
                        defaultValue={product[ind].qty}
                        min={1}
                        variant="small"
                      />
                    </>
                  )}
                </Field>
              ))}
            </Panel>
          )}
        </Table.Cell>
        <Table.Cell
          styles={{ display: "flex", paddingTop: theme.spacing.xlarge3 }}
        >
          <button
            type="button"
            disabled={isLoading}
            onClick={() => setAdjustQuantity(!adjustQuantity)}
            css={[
              {
                padding: theme.spacing.xsmall,
                backgroundColor: theme.colors.accent.base,
                borderRadius: "50%",
                color: theme.colors.light.base,
                marginRight: theme.spacing.xsmall,
              },
              isLoading && { cursor: "not-allowed", opacity: 0.5 },
            ]}
          >
            <Icon.Controls color="dark" size="small" />
          </button>
          <FailModal
            isDisabled={isLoading}
            duplicateRow={duplicateRow}
            mutate={postBasketInvoiceCustomer}
            finishSubmit={finishSubmit}
            isMutationError={isErrorPostBasketInvoiceCustomer}
          />
          <DeleteModal isDisabled={isLoading} deleteRow={deleteRow} />
        </Table.Cell>
      </Table.Row>
      <DuplicateModal
        styles={{
          "@media (min-width: 768px)": {
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.large,
          },
        }}
      >
        <div className="flex justify-center mb-6">
          <img src={Warning} alt="Warning" width={90} />
        </div>
        <Text fontSize="small">
          {translate("warningModal.withPostcode")}{" "}
          <span className="font-bold">{poToCheck.postcode}</span> <br />
          {translate("warningModal.withPo")}{" "}
          <span className="font-bold">{poToCheck.poNumber}</span> <br />
          {translate("warningModal.alreadyExists")}
        </Text>
        <Button
          className="mt-5"
          size="medium"
          color="warning"
          onClick={() => {
            setIsDuplicateModalVisible(false);
          }}
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      </DuplicateModal>
      <LimitModal
        styles={{
          "@media (min-width: 768px)": {
            paddingTop: theme.spacing.large,
            paddingBottom: theme.spacing.large,
          },
        }}
      >
        <div className="flex justify-center mb-6">
          <img src={Warning} alt="Warning" width={90} />
        </div>
        <Text fontSize="small">
          {translate("headings.bookingLimitReached")}
        </Text>
        <Button
          className="mt-5"
          size="medium"
          color="warning"
          onClick={() => {
            setIsLimitModalVisible(false);
          }}
        >
          {translate("modal.buttons.labels.close")}
        </Button>
      </LimitModal>
    </>
  );
};
