import React from "react";
import { useTranslation } from "react-i18next";
import { camelToCapital } from "@clearabee/ui-sdk";
import { IReportJob } from "@clearabee/api-schemas";
import {
  Box,
  FieldLabel,
  Heading,
  Panel,
  theme,
  Text,
  formatCurrency,
} from "@clearabee/ui-library";

const excludeKeys: Array<keyof IReportJob> = [
  "id",
  "timestamp",
  "date",
  "jobRef",
  "addressLatLng",
  "postcode",
  "bcId",
  "status",
  "orderType",
  "companyCode",
];

interface ReportDetailsProps {
  reports: IReportJob[];
}
export const ReportDetails = ({
  reports,
}: ReportDetailsProps): React.ReactElement => {
  const [translate] = useTranslation("jobs");

  return (
    <Panel className="my-12" shadow={false}>
      <Heading
        color="brand"
        level={5}
        styles={{ marginBottom: theme.spacing.small }}
      >
        {translate("headings.reports", {
          count: reports.length,
        })}
      </Heading>
      {reports.map((report) => (
        <Box
          className="flex flex-wrap py-2 px-5 bg-gray-300 rounded-md mb-2"
          key={`report-${report.id}-${report.bcId}`}
        >
          {Object.entries(report)
            .filter(([key]) => !excludeKeys.includes(key as keyof IReportJob))
            .map(([key, value]) => {
              return (
                <Box
                  className="w-1/4"
                  key={`report-${report.id}-${report.bcId}-${camelToCapital(
                    key,
                  )}`}
                >
                  <FieldLabel
                    label={camelToCapital(key)}
                    styles={{
                      margin: `${theme.spacing.xsmall2} 0`,
                    }}
                  >
                    <Text fontSize="small" className="truncate">
                      {key === "invoiceAmount"
                        ? formatCurrency(Number(value))
                        : String(value)}
                    </Text>
                  </FieldLabel>
                </Box>
              );
            })}
        </Box>
      ))}
    </Panel>
  );
};
