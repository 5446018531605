import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { instance } from "@clearabee/ui-sdk";
import {
  Button,
  Modal,
  Box,
  Heading,
  Text,
  Form,
  Input,
  Icon,
  Message,
  displayErrorMessage,
} from "@clearabee/ui-library";
import { stringRequired } from "validation/common";
import { toasts } from "helpers";

interface Job {
  id: string;
  jobRef: string;
  onSuccess?: () => void;
}

export const AllocateTip = (job: Job): React.ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [translate] = useTranslation("jobs");

  const { data: tips, isLoading: isLoadingTips } = useQuery(
    ["getTips"],
    async () => {
      return (
        await instance.jobs.getTips({
          params: {
            limit: 2000,
          },
        })
      ).data.items;
    },
    { retry: false },
  );

  const {
    mutate: patchJob,
    isLoading: isLoadingPatchJob,
    isSuccess: isSuccessPatchJob,
    error: errorPatchJob,
  } = useMutation(
    ["updateTip"],
    async (tipId: string) => {
      await instance.jobs.putTipData(String(job?.id), tipId);
    },
    {
      onSuccess: () => {
        toasts.success({
          message: translate("toasts.tipUpdated"),
        });
        job.onSuccess?.();
      },
    },
  );

  return (
    <>
      <Button size="xsmall" onClick={() => setIsModalVisible(true)}>
        Allocate Tip
      </Button>
      <Modal
        width={500}
        modalVisible={isModalVisible}
        closeButtonStyles={{
          top: 15,
          right: 15,
        }}
        onClose={() => {
          setIsModalVisible(false);
        }}
      >
        <Box className="border-b pb-2 mb-5 flex flex-row justify-between items-center">
          <Heading level={5} color="brand" className="pr-4 w-full sm:w-auto">
            Select Tip
          </Heading>
        </Box>
        <Box className="mt-6 text-left">
          <Text fontSize="small">
            <b>Job Reference:</b> {job?.jobRef}
          </Text>
        </Box>
        <Form
          validationSchema={Yup.object().shape({
            tipId: stringRequired,
          })}
          initialValues={{ tipId: "" }}
          onSubmit={(values) => {
            patchJob(values.tipId);
          }}
        >
          {({ Field }) => (
            <>
              <Box className="mb-8 text-left">
                <Field name="tipId">
                  {({ field }) => (
                    <Input.Select
                      {...field}
                      options={tips?.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))}
                      isLoading={isLoadingTips}
                    />
                  )}
                </Field>
              </Box>
              {/* SAVE BUTTON */}
              <Box className="flex justify-center">
                <Button
                  type="submit"
                  className="flex justify-center gap-x-2"
                  size="small"
                  disabled={isLoadingPatchJob || isSuccessPatchJob}
                >
                  {translate("buttons.labels.save")}
                  {isLoadingPatchJob && <Icon.Loading size="small" />}
                </Button>
              </Box>
              {/* DISPLAY ERROR */}
              {!!errorPatchJob &&
                displayErrorMessage(
                  errorPatchJob ?? translate("toasts.error"),
                  ({ children }) => (
                    <Box className="flex flex-col items-center mt-2">
                      <Message type="error">{children}</Message>
                    </Box>
                  ),
                )}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
